import { AxiosError, AxiosResponse } from 'axios';
import { Maybe, object, ObjectSchema, string } from 'yup';

import { useMutation } from '@tanstack/react-query';
import { api } from '@utils/index';
import { notify } from '@utils/notify';

import { IBackendError } from '@/types/common/IBackendError';

export type IOtpSendRequest = {
  email: string | null;
  phone: string | null;
};

export const otpSendInitial: IOtpSendRequest = {
  email: '',
  phone: '',
};

export const otpSendValidation: ObjectSchema<IOtpSendRequest> = object().shape({
  email: string().required(),
  phone: string().required(),
});

export function useOtpSend() {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<Maybe<IBackendError>>,
    IOtpSendRequest
  >(
    (payload) =>
      api.post('/user-service/api/insurance-request-otp/send', payload),
    {
      onError: (error) => {
        notify('error', error.message);
      },
    }
  );
}
