import { useNavigate } from 'react-router-dom';

import { AxiosError, AxiosResponse } from 'axios';
import { Maybe, object, ObjectSchema, string } from 'yup';

import { storageKeys } from '@consts/storageKeys';
import { useMutation } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IBackendError } from '@/types/common/IBackendError';
import { IAuth } from '@/types/IAuth';

export type IUserOtpConfirmRequest = {
  identifier: string | null;
  otp: string;
};

export const otpConfirmInitial: IUserOtpConfirmRequest = {
  identifier: null,
  otp: '',
};

export const otpConfirmValidation: ObjectSchema<IUserOtpConfirmRequest> =
  object().shape({
    identifier: string()
      .required()
      .min(13, 'ИИН/БИН должен быть не менее 12 символов')
      .max(13, 'ИИН/БИН должен быть не более 12 символов'),
    otp: string().required(),
  });

export function useConfirmOtp() {
  const navigate = useNavigate();

  return useMutation<
    AxiosResponse<IAuth>,
    AxiosError<Maybe<IBackendError>>,
    IUserOtpConfirmRequest
  >((payload) => api.post('/user-service/api/auth/confirm-otp', payload), {
    onSuccess: (response) => {
      if (response.data.access_token) {
        localStorage.setItem(
          storageKeys.tokenAccess,
          response.data.access_token
        );
        localStorage.setItem(
          storageKeys.tokenRefresh,
          response.data.refresh_token
        );
        navigate('/');
      }
    },
  });
}
