import { Route, Routes } from 'react-router-dom';

import AdminLayout from '@layouts/AdminLayout';
import AuthLayout from '@layouts/AuthLayout';
import { ApplicationsRouter } from '@modules/applications/ApplicationsRouter';
import { ContractsRouter } from '@modules/contracts/ContractsRouter';
import AgreementPage from '@modules/pages/AgreementPage';
import LoginPage from '@modules/pages/LoginPage';
import RegisterPage from '@modules/pages/RegisterPage';
import IndexRoute from '@routes/IndexRoute';

const App = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route element={<LoginPage />} path="login" />
        <Route element={<RegisterPage />} path="register" />
        <Route element={<AgreementPage />} path="agreement" />
      </Route>

      <Route element={<AdminLayout />} path="*">
        <Route element={<IndexRoute />} index />
        <Route element={<ContractsRouter />} path="contracts/*" />
        <Route element={<ApplicationsRouter />} path="applications/*" />
      </Route>
    </Routes>
  );
};

export default App;
