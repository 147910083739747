import { AxiosError, AxiosResponse } from 'axios';
import { Maybe, number, object, ObjectSchema, string } from 'yup';

import { useMutation } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IBackendError } from '@/types/common/IBackendError';

export type IStatementsDocumentsUploadRequest = {
  fileUrl?: string;
  requirementId?: number;
  statementId?: number;
  title?: string;
  type?: 'COMPANY' | 'INDIVIDUAL';
};

export const statementsDocumentsUploadInitial: IStatementsDocumentsUploadRequest =
  {
    fileUrl: '',
    requirementId: 0,
    statementId: 0,
    title: '',
    type: 'COMPANY',
  };

export const statementsDocumentsUploadValidation: ObjectSchema<IStatementsDocumentsUploadRequest> =
  object().shape({
    fileUrl: string(),
    requirementId: number(),
    statementId: number(),
    title: string(),
    type: string<'COMPANY' | 'INDIVIDUAL'>(),
  });

export function useStatementsDocumentsUpload() {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<Maybe<IBackendError>>,
    IStatementsDocumentsUploadRequest
  >(
    (payload) => api.post(`/contract-service/api/statement-documents`, payload),
    {}
  );
}
