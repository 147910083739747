import { storageKeys } from '@consts/storageKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@utils/index';

export function useLogout() {
  return useMutation((payload) => api.post('/user-service/api/auth/logout'), {
    onSuccess: (response) => {
      localStorage.removeItem(storageKeys.tokenAccess);
      localStorage.removeItem(storageKeys.tokenRefresh);
    },
  });
}

export function useLogoutSoft() {
  const queryClient = useQueryClient();

  async function logout() {
    localStorage.removeItem(storageKeys.tokenAccess);
    localStorage.removeItem(storageKeys.tokenRefresh);

    await queryClient.resetQueries({
      predicate: () => true,
    });
  }

  return { logout };
}
