import { Route, Routes } from 'react-router-dom';

import ApplicationDetailsPage from './pages/ApplicationDetailsPage';
import ApplicationsPage from './pages/ApplicationsPage';

export const ApplicationsRouter = () => {
  return (
    <Routes>
      <Route element={<ApplicationsPage />} index />
      <Route element={<ApplicationDetailsPage />} path="/:id" />
    </Routes>
  );
};
