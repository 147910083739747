import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@mantine/core';

export default function ApplicationComplete() {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return (
    <div className="flex h-full  flex-col items-center rounded-[32px] bg-white px-12 py-[60px] text-center">
      <div className="w-[60%]">
        <h3 className="mb-4">Ваше Заявление принято! </h3>
        <span>
          Мы получили ваше заявление <b>“о выплате по страховому случаю”</b>, и
          в данный момент оно находится в обработке
        </span>
      </div>

      <img
        alt="Complete"
        className="my-8 h-[140px]"
        src="/images/icons/complete.svg"
      />

      <Link to="/applications">
        <Button color="gray" variant="light">
          Вернуться в личный кабинет
        </Button>
      </Link>
    </div>
  );
}
