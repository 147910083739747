import { FC, useState } from 'react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import { AxiosError } from 'axios';

import { useLogout } from '@api/auth/useLogout';
import { useRefreshToken } from '@api/auth/useRefreshToken';
import { storageKeys } from '@consts/storageKeys';
import { notify } from '@utils/notify';

export const AppQueryContextProvider: FC<any> = (props) => {
  const logout = useLogout();
  const refreshTokenQuery = useRefreshToken();

  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          cacheTime: 0,
          refetchOnWindowFocus: false,
          retry: false,
        },
      },
      mutationCache: new MutationCache({
        onError: async (error, variables, context, mutation) => {
          const err = error as AxiosError;

          const isAuthRequest =
            err.response?.config.url?.includes(
              '/protocol/openid-connect/token'
            ) &&
            (err.response?.config.method === 'POST' ||
              err.response?.config.method === 'post');

          const receivedUnauthorizedError = err.response?.status !== 401;

          if (receivedUnauthorizedError || isAuthRequest) return;

          await mutation.cancel();
          const refreshToken = localStorage.getItem('tokenRefresh') as string;
          refreshTokenQuery
            .mutateAsync({
              refreshToken,
            })
            .then(async (data) => {
              const { access_token, refresh_token } = data.data;
              localStorage.setItem(storageKeys.tokenRefresh, refresh_token);
              localStorage.setItem(storageKeys.tokenAccess, access_token);
            })
            .catch((_err) => {
              notify('error', 'Срок действия сессии истек');
              logout.mutateAsync();
            });
        },
      }),
      queryCache: new QueryCache({
        onError: async (error, query) => {
          const err = error as AxiosError;
          if (!err.response) {
            return;
          }
          if (err.response?.status !== 401) return;
          await query.invalidate();
          const refreshToken = localStorage.getItem('tokenRefresh') as string;
          refreshTokenQuery
            .mutateAsync({
              refreshToken,
            })
            .then(async (data) => {
              const { access_token, refresh_token } = data.data;
              localStorage.setItem(storageKeys.tokenRefresh, refresh_token);
              localStorage.setItem(storageKeys.tokenAccess, access_token);
              await query.fetch();
            })
            .catch((_err) => {
              notify('error', 'Срок действия сессии истек');
              logout.mutateAsync();
            });
        },
      }),
    })
  );

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
    </QueryClientProvider>
  );
};
