import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

type IFileUploadLinkRequest = {
  file: FormData | null;
  fileName: string;
};

type IFileUploadLinkResponse = {
  contentType: string;
  createdTime: string;
  fileSize: number;
  filename: string;
  url: string;
};
export function useMutateFileUploadLink({ ...params }: { directory: string }) {
  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<IFileUploadLinkResponse>,
    AxiosError,
    IFileUploadLinkRequest
  >(
    (payload) =>
      api.post(`/s3-adapter-service/api/files/upload`, payload.file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          directory: params.directory,
          key: payload.fileName,
        },
      }),
    {
      onError: () => {
        notify('error', 'Что-то пошло не так, повторите попытку позже.');
      },
      onSuccess: () => queryClient.invalidateQueries([queryKeys.statement]),
    }
  );
}
