import { FC, MemoExoticComponent, SVGProps } from 'react';

import { Image, Title } from '@mantine/core';

export const NotFoundResult: FC<{
  Icon?: MemoExoticComponent<(props: SVGProps<SVGSVGElement>) => JSX.Element>;
  subTitle?: string;
  title?: string;
}> = ({
  subTitle = 'Попробуйте перезагрузить страницу',
  title = 'Страница не найдена',
}) => {
    return (
      <div className="flex h-fit flex-col items-center justify-center gap-4 px-2 py-8 text-center">
        <Image src="/images/illustrations/empty.svg" w={200} />
        <Title className=" leading-7 !font-semibold mt-4 !text-base !text-2xl" order={2}>
          {title}
        </Title>
        <span className="text-base text-gray-400">{subTitle}</span>
        <a className='bg-[#FFF] text-black py-3 px-[70px] rounded-2xl text-sm' href="dev.bcclife.xyz">Перейти на сайт</a>
      </div>
    );
  };