import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';
import { useParams } from 'react-router-dom';

import { useStatementChangeStatus } from '@api/applications/useStatementChangeStatus';
import { useCurrent } from '@api/auth/useCurrent';
import {
  IOtpConfirmRequest,
  otpConfirmInitial,
  otpConfirmValidation,
  useOtpConfirm,
} from '@api/otp/confirmOtp';
import { useOtpSend } from '@api/otp/sendOtp';
import {
  IRequisitesRequest,
  requisitesInitial,
  requisitesValidation,
  useRequisites,
} from '@api/requisites/useRequisites';
import BccShieldIcon from '@components/atoms/icons/BccShieldIcon';
import StatementIcon from '@components/atoms/icons/StatementIcon';
import { Button, InputBase, PinInput, Select, Text } from '@mantine/core';
import { Alert, Checkbox } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconInfoCircle } from '@tabler/icons-react';

import { IApplication } from '@/types/IApplication';

import { ApplicationStatusTag } from '../atoms/ApplicationStatusTag';

export default function ApplicationPayment({
  onFinish,
  statement,
}: {
  onFinish: () => void;
  statement: IApplication;
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const meQuery = useCurrent();
  const { id = '' } = useParams<{ id: string }>();
  const [step, setStep] = useState(1);
  const [agreement, setAgreement] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);
  const [otpSendPath, setOtpSendPath] = useState('');

  const requisites = useRequisites({
    statementId: +id,
  });

  const form = useForm<IRequisitesRequest>({
    initialValues: requisitesInitial,
    validate: yupResolver(requisitesValidation),
  });

  const confirmOtp = useOtpConfirm();
  const sendOtp = useOtpSend();

  const changeStatus = useStatementChangeStatus({
    id: +id,
    status: statement.status,
  });

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(29);

  const handleSendCode = () => {
    sendOtp
      .mutateAsync({
        email: meQuery.data?.data.email
          ? meQuery.data?.data.email.trim()
          : null,
        phone: meQuery.data?.data.phone
          ? meQuery.data?.data.phone.trim()
          : null,
      })
      .then(() => {
        setIsLoading(false);
        setMinutes(0);
        setSeconds(29);
      });
  };

  const otpConfirmForm = useForm<IOtpConfirmRequest>({
    initialValues: otpConfirmInitial,
    validate: yupResolver(otpConfirmValidation),
  });

  useEffect(() => {
    if (step == 2) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes(0);
            setSeconds(29);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  useEffect(() => {
    if (meQuery.data?.data.phone || meQuery.data?.data.email) {
      otpConfirmForm.setFieldValue(
        'phone',
        meQuery.data?.data.phone || undefined
      );
      otpConfirmForm.setFieldValue(
        'email',
        meQuery.data?.data.email || undefined
      );
    }
  }, [meQuery.data?.data]);

  return (
    <div className="grid gap-8 rounded-[24px] bg-white px-8 py-6">
      {step == 1 && (
        <>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <StatementIcon
                stroke="1.2"
                style={{ height: '55px', width: '55px' }}
              />
              <h6>Извещение {statement.statementNumber}</h6>
            </div>
            <ApplicationStatusTag status={statement.status} />
          </div>
          <Alert
            color="orange"
            icon={<IconInfoCircle />}
            radius="lg"
            title="Внимание! Ваше извещение принято"
            variant="light"
          >
            Для продолжения процесса подачи заявления на выплату, пожалуйста,
            ознакомьтесь с суммой, предложенной к выплате. Если вы согласны с
            указанной суммой, подтвердите свое согласие через одноразовый пароль
            (OTP), который будет отправлен с помощью установленного способа
            связи.
          </Alert>

          <div>
            <p className="text-center text-base font-normal">
              Итоговая сумма страховой выплаты будет составлять:
            </p>
            <h2 className="text-center text-[42px] font-bold not-italic text-[#0B7B94]">
              {statement.amount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              тг
            </h2>
            <p>{statement.amountComment}</p>
          </div>

          <div className="mt-6 flex flex-col items-center">
            <div className="my-4 w-full border-t border-gray-300"></div>
            <Checkbox
              checked={agreement}
              className="mb-4"
              label="Я ознакомлен с суммой и даю свое согласие на сбор, обработку и использование моих персональных данных"
              onChange={(event) => setAgreement(event.currentTarget.checked)}
            />
            <Button
              color="#0B7B94"
              disabled={!agreement}
              loading={requisites.isLoading}
              onClick={() => setStep(2)}
              w={220}
            >
              Подать заявление
            </Button>
          </div>
        </>
      )}

      {step == 2 && (
        <div>
          <form
            className="w-full"
            onSubmit={otpConfirmForm.onSubmit((values) => {
              console.log('values: ', values);
              confirmOtp
                .mutateAsync({
                  ...values,
                  otp: values.otp.trim(),
                })
                .then(() => {
                  setStep(3);
                  setIsLoading(false);
                });
              // .catch((error) => console.log(error));
            })}
          >
            <div className="my-8 text-center">
              <Text>{`Мы выслали код на ваш номер +7 *** *** **${meQuery.data?.data.phone.slice(-2)}`}</Text>
              <PinInput
                className="mt-5 items-center justify-center"
                oneTimeCode
                placeholder=""
                size="md"
                type="number"
                {...otpConfirmForm.getInputProps('otp')}
              />
              {otpConfirmForm.errors && (
                <Text c="dimmed" mt={8} size="sm">
                  {/* {t('auth.errors.otpCodeNotValid')} */}
                </Text>
              )}
            </div>

            <div className="flex flex-col items-center">
              {/* FIXME: add translation */}

              <Button
                className="mx-auto mb-4 mt-4 border-2 md:px-8"
                disabled={seconds != 0}
                onClick={handleSendCode}
                size="md"
              >
                Запросить код повторно через {minutes}:
                {seconds < 10 ? `0${seconds}` : seconds} секунд
              </Button>

              <Button
                className="mx-auto w-[200px] border-2 md:px-8"
                loading={isLoading}
                type="submit"
              >
                Отправить
              </Button>
            </div>
          </form>
        </div>
      )}

      {step == 3 && (
        <div className="flex h-full flex-col items-center rounded-[32px] bg-white px-12 py-10">
          <div className=" mb-8 grid gap-3 text-left">
            <h3>Заявление на страховую выплату</h3>
            <span className="text-[#8B8E99]">
              Заполните поля для получения выплаты по страховому случаю
            </span>
          </div>

          <form
            className="grid w-full gap-6"
            onSubmit={form.onSubmit((values) => {
              requisites
                .mutateAsync({
                  ...values,
                })
                .then(() => {
                  return changeStatus.mutateAsync();
                });
            })}
          >
            <h6>Реквизиты</h6>
            <Select
              className="h-[56px]"
              data={['Банк центр кредит']}
              label="БИК и ли наименование банка*"
              name="bankName"
              variant="filled"
              {...form.getInputProps('bankName')}
            />
            <InputBase
              component={IMaskInput}
              label="Корреспонденский счет банка*"
              mask="00000 00000 00000 00000"
              name="bankAccount"
              placeholder="XXXXX XXXXX XXXXX XXXXX"
              variant="filled"
              {...form.getInputProps('bankAccount')}
            />
            <InputBase
              component={IMaskInput}
              label="Счет получателя*"
              mask="00000 00000 00000 00000"
              name="recipientsAccount"
              placeholder="XXXXX XXXXX XXXXX XXXXX"
              variant="filled"
              {...form.getInputProps('recipientsAccount')}
            />
            <div className="flex items-center gap-3 rounded-xl bg-[#E5F4F7] px-4 py-3">
              <BccShieldIcon style={{ height: '24px', width: '24px' }} />
              <span className="text-[#676971]">
                Ваши данные под надежной защитой
              </span>
            </div>
            <div className="flex w-full justify-end">
              <Button
                color="#0B7B94"
                loading={requisites.isLoading}
                type="submit"
                w={220}
              >
                Отправить
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
