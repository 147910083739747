import { Route, Routes } from 'react-router-dom';

import ContractDetailsPage from './pages/ContractDetailsPage';
import ContractsPage from './pages/ContractsPage';

export const ContractsRouter = () => {
  return (
    <Routes>
      <Route element={<ContractsPage />} index />
      <Route element={<ContractDetailsPage />} path="/:id" />
    </Routes>
  );
};
