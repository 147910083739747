import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgSafetyIconsvg = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="42px" viewBox="0 0 55 55" width="42px" {...props}>
    <mask
      height={48}
      id="mask0_3184_7679"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={43}
      x={6}
      y={6}
    >
      <path
        clipRule="evenodd"
        d="M48.0938 6.87109H6.84375V30.9641C6.84375 37.3509 10.1675 43.2773 15.6172 46.6077L27.4688 53.8503L39.3203 46.6077C44.7701 43.2773 48.0938 37.3509 48.0938 30.9641V6.87109ZM41.2188 13.7461H13.7188V30.5304C13.7188 34.3616 15.6335 37.9392 18.8211 40.0644L27.4688 45.8294L36.1164 40.0644C39.304 37.9392 41.2188 34.3616 41.2188 30.5304V13.7461Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        d="M13.7266 13.7461H41.2266V30.5304C41.2266 34.3616 39.3118 37.9392 36.1242 40.0644L27.4766 45.8294L18.8289 40.0644C15.6413 37.9392 13.7266 34.3616 13.7266 30.5304V13.7461Z"
        fill="url(#paint0_linear_3184_7679)"
      />
    </mask>
    <g mask="url(#mask0_3184_7679)">
      <path d="M-0.0234375 -0.00390625H54.9766V54.9961H-0.0234375V-0.00390625Z" fill="#0B7B94" />
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_3184_7679"
        x1={11.4349}
        x2={35.4974}
        y1={9.16276}
        y2={38.9544}
      >
        <stop stopOpacity={0.55} />
        <stop offset={1} stopOpacity={0.2} />
      </linearGradient>
    </defs>
  </svg>
);
const Memo = memo(SvgSafetyIconsvg);
export default Memo;
