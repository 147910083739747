import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@mantine/core';

import { IApplication } from '@/types/IApplication';

export default function ApplicationDocumentsComplete({
  statement,
}: {
  statement: IApplication;
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return (
    <div className="flex h-full  flex-col items-center rounded-[32px] bg-white px-12 py-[60px] text-center">
      <div className="w-[60%]">
        <h3 className="mb-4">Документы отправлены </h3>
        <span>По итогам рассмотрения вы получите уведомление</span>
      </div>

      <img
        alt="Complete"
        className="my-8 h-[140px]"
        src="/images/icons/complete.svg"
      />

      <Link to={`/applications/${statement.id}`}>
        <Button color="gray" variant="light">
          Страница заявления
        </Button>
      </Link>
    </div>
  );
}
