import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { useLogout } from '@api/auth/useLogout';
import ProfileAvatar from '@components/atoms/ProfileAvatar';
import LanguageDropdown from '@components/molecules/LanguageDropdown';
import { SidebarNavLink } from '@components/molecules/SidebarNavLink';
import AdminFooter from '@components/organisms/AdminFooter';
import { sidebars } from '@consts/sidebars';
import { usePageMetaContext } from '@context/PageMetaContext';
import { ActionIcon, AppShell, Burger, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import AuthRoute from '@routes/AuthRoute';
import { IconLogout } from '@tabler/icons-react';

const AdminLayout: FC = () => {
  const logoutQuery = useLogout();

  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
  const { openMenuKeys, selectedMenuKeys } = usePageMetaContext();

  const handleLogout = () => {
    logoutQuery.mutateAsync();
  };

  return (
    <AuthRoute auth>
      <AppShell
        header={{ height: 64 }}
        navbar={{
          breakpoint: 'sm',
          collapsed: { desktop: true, mobile: !mobileOpened },
          width: 240,
        }}
      >
        <AppShell.Header>
          <Group h="100%" justify="space-between" px="xl" wrap="nowrap">
            <Burger
              hiddenFrom="sm"
              onClick={toggleMobile}
              opened={mobileOpened}
              size="sm"
            />
            <img alt="BCC Life" className=" h-[25px] md:h-[34px]" src="/logo.png" />

            <Group gap={32} visibleFrom="sm">
              {sidebars()
                // .filter((item) =>
                //   item.permissions.includes(userMe?.role.toLowerCase() ?? '')
                // )
                .map((item) => (
                  <SidebarNavLink
                    item={item}
                    key={item.key}
                    opened={!desktopOpened}
                    openMenuKeys={openMenuKeys}
                    selectedMenuKeys={selectedMenuKeys}
                  />
                ))}
            </Group>

            <div className="hidden items-center gap-6 md:flex">
              <LanguageDropdown />

              <ProfileAvatar />

              <ActionIcon onClick={handleLogout} variant="white">
                <IconLogout />
              </ActionIcon>
            </div>
          </Group>
        </AppShell.Header>
        <AppShell.Main>
          <Outlet />
        </AppShell.Main>
        <AdminFooter />
      </AppShell>
    </AuthRoute>
  );
};

export default AdminLayout;
