import { Avatar } from '@mantine/core';

import { IUser } from '@/types/IUser';

export default function ProfileAvatar({ me }: { me?: IUser }) {
  return (
    <>
      <Avatar color="#DBAD6C" radius="xl">
        {me?.firstName[0] || 'A'}
        {me?.lastName[0] || 'A'}
      </Avatar>
      {me?.firstName} {me?.lastName[0]}.
    </>
  );
}
