import { AxiosError, AxiosResponse } from 'axios';
import { Maybe, object, ObjectSchema, string } from 'yup';

import { useMutation } from '@tanstack/react-query';
import { api } from '@utils/index';
import { notify } from '@utils/notify';

import { IBackendError } from '@/types/common/IBackendError';

export type IOtpConfirmRequest = {
  email?: string | null;
  otp: string;
  phone?: string | null;
};

export const otpConfirmInitial: IOtpConfirmRequest = {
  email: '',
  otp: '',
  phone: '',
};

export const otpConfirmValidation: ObjectSchema<IOtpConfirmRequest> =
  object().shape({
    email: string().notRequired(),
    otp: string().required(),
    phone: string().notRequired(),
  });

export function useOtpConfirm() {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<Maybe<IBackendError>>,
    IOtpConfirmRequest
  >(
    (payload) =>
      api.post('/user-service/api/insurance-request-otp/confirm', payload),
    {
      onError: (error) => {
        notify('error', error.message);
      },
    }
  );
}
