import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IApplication } from '@/types/IApplication';

type IStatementRequest = { id: number };

type IStatementResponse = AxiosResponse<IApplication>;

export function useStatementById(params: IStatementRequest) {
  return useQuery<IStatementResponse, AxiosError>(
    [queryKeys.statement, params],
    () =>
      api.get(`/contract-service/api/statements/get-statement-id`, {
        params: { id: params.id },
      }),
    { enabled: !!params.id }
  );
}
