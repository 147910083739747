import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgBccNotarizedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="42px" viewBox="0 0 18 22" width="42px" {...props}>
    <path
      clipRule="evenodd"
      d="M0.53125 2.23483C0.53125 1.06529 1.47935 0.117188 2.6489 0.117188H11.1195C11.4003 0.117188 11.6696 0.228742 11.8682 0.42731L17.1623 5.72143C17.3609 5.91999 17.4724 6.18931 17.4724 6.47013V19.176C17.4724 20.3456 16.5243 21.2937 15.3548 21.2937H2.6489C1.47935 21.2937 0.53125 20.3456 0.53125 19.176V2.23483ZM14.9162 6.47013H11.1195V2.67341L14.9162 6.47013ZM9.00184 2.23483V7.52895C9.00184 8.11372 9.47589 8.58778 10.0607 8.58778H15.3548V19.176H2.6489V2.23483L9.00184 2.23483ZM4.76654 11.7642C4.76654 11.1795 5.2406 10.7054 5.82537 10.7054H12.1783C12.7631 10.7054 13.2371 11.1795 13.2371 11.7642C13.2371 12.349 12.7631 12.8231 12.1783 12.8231H5.82537C5.2406 12.8231 4.76654 12.349 4.76654 11.7642ZM4.76654 15.9995C4.76654 15.4148 5.2406 14.9407 5.82537 14.9407H12.1783C12.7631 14.9407 13.2371 15.4148 13.2371 15.9995C13.2371 16.5843 12.7631 17.0584 12.1783 17.0584H5.82537C5.2406 17.0584 4.76654 16.5843 4.76654 15.9995Z"
      fill="#0B7B94"
      fillRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgBccNotarizedIcon);
export default Memo;
