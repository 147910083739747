import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import imageCompression from 'browser-image-compression';
import moment from 'moment';

import { useStatementChangeStatus } from '@api/applications/useStatementChangeStatus';
import { useStatementRequirements } from '@api/applications/useStatementRequirements';
import {
  IStatementsDocumentsUploadRequest,
  statementsDocumentsUploadInitial,
  statementsDocumentsUploadValidation,
  useStatementsDocumentsUpload,
} from '@api/applications/useStatementsDocumentsUpload';
import { useCurrent } from '@api/auth/useCurrent';
import { useMutateFileUploadLink } from '@api/files/useMutateFileUploadLink';
import BccNotarizedIcon from '@components/atoms/icons/BccNotarizedIcon';
// import BccStatementDocumentIcon from '@components/atoms/icons/BccStatementDocumentIcon';
import StatementIcon from '@components/atoms/icons/StatementIcon';
import {
  ActionIcon,
  Alert,
  Button,
  Checkbox,
  Divider,
  Group,
  Text,
} from '@mantine/core';
import {
  Dropzone,
  FileRejection,
  FileWithPath,
  IMAGE_MIME_TYPE,
} from '@mantine/dropzone';
import { useForm, yupResolver } from '@mantine/form';
import {
  IconFile,
  IconInfoCircle,
  IconPaperclip,
  IconX,
} from '@tabler/icons-react';

import { IApplication } from '@/types/IApplication';

import { ApplicationStatusTag } from '../atoms/ApplicationStatusTag';
import ApplicationDocumentsComplete from './ApplicationDocumentsComplete';

export default function ApplicationDetails({
  statement,
}: {
  statement: IApplication;
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const [agreement, setAgreement] = useState<boolean>(false);
  const [documentsComplete, setDocumentsComplete] = useState<boolean>(false);

  const statementRequirementsQuery = useStatementRequirements({
    page: 0,
    product: statement.type == 'COMPANY' ? 'OSNS' : 'LIFE',
    reason: statement.reason,
    size: 100,
  });
  const statementRequirements = statementRequirementsQuery.data?.content;

  const [statementRequirementsFiles, setStatementRequirementsFiles] = useState<
    any[]
  >([]);

  useEffect(() => {
    if (statementRequirements) {
      const arr = statementRequirements.map((item) => {
        return {
          documentName: item.documentName,
          files: [] as FileWithPath[] | FileRejection[],
          id: item.id,
          isRequired: item.isRequired,
          notarized: item.notarized,
        };
      });
      setStatementRequirementsFiles(arr);
    }
  }, [statementRequirements]);

  const meQuery = useCurrent();
  const { id = '' } = useParams<{ id: string }>();

  const upload = useStatementsDocumentsUpload();

  const changeStatus = useStatementChangeStatus({
    id: +id,
    status: 'IN_PROCESSING_DOCUMENTS',
  });

  const form = useForm<IStatementsDocumentsUploadRequest>({
    initialValues: {
      ...statementsDocumentsUploadInitial,
      statementId: Number(id),
      type: statement.type,
    },
    validate: yupResolver(statementsDocumentsUploadValidation),
  });

  const uploadLink = useMutateFileUploadLink({
    directory: `files-statements`,
  });
  const [filesFormData, setFilesFormData] = useState<
    {
      file: FormData;
      fileName: string;
      requirementId: number;
    }[]
  >([]);

  useEffect(() => {
    if (statementRequirementsFiles) {
      const formDatas: {
        file: FormData;
        fileName: string;
        requirementId: number;
      }[] = [];
      statementRequirementsFiles.forEach((item) => {
        item.files.forEach((file) =>
          imageCompression(file, {
            alwaysKeepResolution: true,
            maxSizeMB: 0.2,
            maxWidthOrHeight: 300,
            useWebWorker: true,
          }).then((compressedFile) => {
            const formData = new FormData();
            formData.append('file', compressedFile as File, `${file.name}`);
            formDatas.push({
              file: formData,
              fileName: file.name,
              requirementId: +item.id,
            });
          })
        );
      });
      setFilesFormData(formDatas);
    }
  }, [statementRequirementsFiles]);

  useEffect(() => {
    if (form.errors) {
      console.log('errors: ', form.errors);
      console.log('filesFormData: ', filesFormData);
    }
  }, [form.errors]);

  return (
    <>
      {[
        'WAITING_FOR_DOCUMENTS',
        'IN_PROCESSING_DOCUMENTS',
        'REJECTED',
      ].includes(statement.status) && (
        <>
          <div className="grid gap-8 rounded-[24px] bg-white px-8 py-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <StatementIcon
                  stroke="1.2"
                  style={{ height: '55px', width: '55px' }}
                />
                <h6>Извещение {statement.statementNumber}</h6>
              </div>

              <ApplicationStatusTag status={statement.status} />
            </div>
            {statement.status == 'WAITING_FOR_DOCUMENTS' && (
              <Alert
                color="orange"
                icon={<IconInfoCircle />}
                radius="lg"
                title="Внимание! Необходимо загрузить документы"
                variant="light"
              >
                Для рассмотрения Вашего обращения по заявленному событию, просим
                предоставить следующий комплект документов:
              </Alert>
            )}
          </div>

          <div className="rounded-[24px] bg-white px-8 py-6">
            <h6>Данные:</h6>
            <div className="grid grid-cols-1 gap-3 py-8 sm:grid-cols-2">
              <div>
                <Text c="#6D758F" fw={400} size="sm">
                  Застрахованный:
                </Text>
                <Text c="#464C5D" fw={500} size="md">
                  {statement.acquirer || '-'}
                </Text>
              </div>

              <div>
                <Text c="#6D758F" fw={400} size="sm">
                  Выгодоприобретатель:
                </Text>
                <Text c="#464C5D" fw={500} size="md">
                  {statement.beneficiary || '-'}
                </Text>
              </div>

              <div>
                <Text c="#6D758F" fw={400} size="sm">
                  Страховая сумма:
                </Text>
                <Text c="#464C5D" fw={500} size="md">
                  {statement.insuranceAmount || '-'}
                </Text>
              </div>

              <div>
                <Text c="#6D758F" fw={400} size="sm">
                  Страховая премия:
                </Text>
                <Text c="#464C5D" fw={500} size="md">
                  {statement.insurancePremium || '-'}
                </Text>
              </div>

              <div className="items-center">
                <Text c="#6D758F" fw={400} size="sm">
                  Дата и время заявления:
                </Text>
                <Text c="#6D758F" fw={400} size="sm">
                  {moment(statement.createdAt).format('DD MMMM YYYY, HH:mm')}
                </Text>
              </div>

              <div className="items-center">
                <Text c="#6D758F" fw={400} size="sm">
                  Дата и время последнего обн.:
                </Text>
                <Text c="#6D758F" fw={400} size="sm">
                  {moment(statement.updatedAt).format('DD MMMM YYYY, HH:mm')}
                </Text>
              </div>
            </div>
          </div>

          <div className="grid gap-8 rounded-[24px] bg-white px-8 py-6">
            <form
              onSubmit={form.onSubmit((values) => {
                if (filesFormData) {
                  filesFormData.map((file) => {
                    uploadLink
                      .mutateAsync({
                        file: file.file,
                        fileName: file.fileName,
                      })
                      .then((response) => {
                        return upload.mutateAsync({
                          ...values,
                          fileUrl: `${response.data.url}`,
                          requirementId: file.requirementId,
                          statementId: statement.id,
                          title: `${response.data.filename}`,
                        });
                      })
                      .finally(() => {
                        setDocumentsComplete(true);
                        return changeStatus.mutateAsync();
                      });
                  });
                }
              })}
            >
              <h6 className="mb-8">Список обязательных документов</h6>

              {/* <div className="grid gap-4">
                {statement.statementDocuments &&
                  statement.statementDocuments?.length > 0 &&
                  statement.statementDocuments?.map((document) => (
                    <FileCard file={document} key={document.id} />
                  ))}
              </div> */}

              {statement.status == 'WAITING_FOR_DOCUMENTS' &&
                statementRequirementsFiles &&
                statementRequirementsFiles.length > 0 &&
                statementRequirementsFiles.map((requirement, index) => (
                  <>
                    <Alert
                      color="orange"
                      icon={<IconInfoCircle />}
                      radius="lg"
                      title="Внимание! Необходимые копии документа-(ов) должны быть нотариально заверенными"
                      variant="light"
                    />
                    <div className="mt-8">
                      <Text mb={8}>
                        {/* <BccStatementDocumentIcon /> */}
                        {index + 1}. Прикрепите файл (-ы){' '}
                        {requirement.documentName}
                      </Text>
                      <>
                        <Dropzone
                          accept={IMAGE_MIME_TYPE}
                          multiple
                          onDrop={(items) => {
                            const modifiedObj = {
                              ...requirement,
                              files: items,
                            };
                            const result = statementRequirementsFiles.map(
                              (item) =>
                                item.id === modifiedObj.id ? modifiedObj : item
                            );
                            setStatementRequirementsFiles(result);
                          }}
                          onReject={(items) => {
                            const modifiedObj = {
                              ...requirement,
                              files: items,
                            };
                            const result = statementRequirementsFiles.map(
                              (item) =>
                                item.id === modifiedObj.id ? modifiedObj : item
                            );
                            setStatementRequirementsFiles(result);
                          }}
                        >
                          <Group
                            gap="xl"
                            justify="center"
                            mih={48}
                            style={{ pointerEvents: 'none' }}
                          >
                            <div className="flex flex-col items-center justify-center">
                              <Text c="#66727F" inline size="sm">
                                Перетащите файлы или выберите на компьютере
                              </Text>
                              <Text
                                c="#0D4CD3"
                                className="flex items-center"
                                inline
                                mt={7}
                                size="sm"
                              >
                                <IconPaperclip
                                  className="mr-2"
                                  color="#0D4CD3"
                                />
                                Выбрать файл
                              </Text>
                            </div>
                          </Group>
                        </Dropzone>
                        <span className="text-xs text-[#6D758F]">
                          Допустимые форматы загрузки файлов: pdf, doc, docx,
                          jpg, jpeg, png, svg. Размер загружаемого файл не
                          должен превышать 30 MB.
                        </span>

                        <div className="mt-6 grid gap-3">
                          {requirement.files?.map((file, fileIndex) => (
                            <div
                              className="flex items-center justify-between rounded-md bg-[#F5F6FB] p-2 hover:bg-[#E1F0F3]"
                              key={fileIndex}
                            >
                              <div className="flex items-center">
                                <IconFile
                                  style={{ height: '48px', width: '48px' }}
                                />
                                {file.name}
                              </div>
                              <ActionIcon
                                onClick={() => {
                                  const modifiedObj = {
                                    ...requirement,
                                    files: requirement.files.filter(
                                      (_, i) => i !== fileIndex
                                    ),
                                  };
                                  const result = statementRequirementsFiles.map(
                                    (item) =>
                                      item.id === modifiedObj.id
                                        ? modifiedObj
                                        : item
                                  );
                                  setStatementRequirementsFiles(result);
                                }}
                              >
                                <IconX />
                              </ActionIcon>
                            </div>
                          ))}
                          {statement?.statementDocuments?.map(
                            (file, fileIndex) =>
                              requirement.id == file.requirementId && (
                                <div
                                  className="flex items-center justify-between rounded-md bg-[#F5F6FB] p-2 hover:bg-[#E1F0F3]"
                                  key={fileIndex}
                                >
                                  <div className="flex items-center">
                                    <IconFile
                                      style={{ height: '48px', width: '48px' }}
                                    />
                                    {file.title}
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </>
                      <Divider className="my-8" color="#99B1E6" />
                    </div>
                  </>
                ))}
              {statement.status == 'WAITING_FOR_DOCUMENTS' && (
                <div className="my-4 flex flex-col items-center justify-evenly">
                  <Checkbox
                    checked={agreement}
                    className="mb-4"
                    label="Я подтверждаю, что загрузил все необходимые документы"
                    onChange={(event) =>
                      setAgreement(event.currentTarget.checked)
                    }
                  />

                  <Button
                    className="mt-5"
                    disabled={!agreement}
                    type="submit"
                    w={220}
                  >
                    Отправить
                  </Button>
                </div>
              )}

              {/* FIXME: add categories */}
              {statement.status == 'IN_PROCESSING_DOCUMENTS' &&
                statementRequirements &&
                statementRequirements.length > 0 &&
                statementRequirements.map((requirements, index) => (
                  <div className="mt-8" key={index}>
                    <Text mb={8}>
                      {index + 1}. {requirements.documentName}
                    </Text>
                    <div className="grid gap-3">
                      {statement.statementDocuments?.map(
                        (file, fileIndex) =>
                          file.requirementId == requirements.id && (
                            <div
                              className="flex items-center justify-between rounded-md bg-[#F5F6FB] p-2 hover:bg-[#E1F0F3]"
                              key={fileIndex}
                            >
                              <div className="flex items-center">
                                <ActionIcon
                                  aria-label="Doc"
                                  className="mr-2"
                                  color={'#FFF'}
                                  radius="md"
                                  size={56}
                                  variant="filled"
                                >
                                  <BccNotarizedIcon
                                    className="icon-file transition duration-200"
                                    color={'#0B7B94'}
                                    style={{
                                      background: 'FFF',
                                      height: '70%',
                                      width: '70%',
                                    }}
                                  />
                                </ActionIcon>
                                <span className="text-[#727173]">
                                  {file.title}
                                </span>
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                ))}
            </form>
          </div>
        </>
      )}
      {/* FIXME: show on documents send */}
      {documentsComplete && (
        <ApplicationDocumentsComplete statement={statement} />
      )}
    </>
  );
}
