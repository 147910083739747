import { Navigate } from 'react-router-dom';

import { sidebars } from '@consts/sidebars';
import { Skeleton } from '@mantine/core';

const IndexRoute = () => {
  const navbar = sidebars().find(() => true);

  if (navbar?.link) return <Navigate to={navbar.link} />;

  // if (me.isFetching) {
  return (
    <div className="md:p-15 mt-5 rounded-2xl bg-white p-10">
      <Skeleton className="h-24 w-full rounded-lg" />
      <Skeleton className="h-24 w-full rounded-lg" />
      <Skeleton className="h-24 w-full rounded-lg" />
    </div>
  );
  // }

  // return <div />;
};

export default IndexRoute;
