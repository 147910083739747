import { MantineThemeOverride } from '@mantine/core';
import { colors } from './colors';


export const mantineTheme: MantineThemeOverride = {
  colors: {
    // FIXME: add shades
    green: [
      '#0B7B94',
      '#0B7B94',
      '#0B7B94',
      '#0B7B94',
      '#0B7B94',
      '#0B7B94',
      '#0B7B94',
      '#0B7B94',
      '#0B7B94',
      '#0B7B94',
    ],
    yellow: [
      '#DBAD6C',
      '#F7BE6D',
      '#C8954D',
      '#C8954D',
      '#C8954D',
      '#C8954D',
      '#C8954D',
      '#C8954D',
      '#C8954D',
      '#C8954D',
    ],
  },
  components: {
    PinInput: {
      styles: () => ({
        root: {
          justifyContent: 'center'
        },
        pinInput: {
          height: '72px',
          width: '63px'
        }
      }),
    },
    Input: {
      styles: () => ({
        input: {
          '&:disabled': { border: 'none' },
          '&:focusWithin': {
            backgroundColor: colors.primary['102'],
            borderColor: colors.primary['400'],
          },
          '&:focus': {
            backgroundColor: colors.primary['102'],
            borderColor: '#0b7b94',
          },
          '&:hover': { backgroundColor: colors.primary['102'], borderColor: colors.primary['400'] },
          '&[dataError]': { borderColor: colors.primary['900'] },
          backgroundColor: 'white',
          borderColor: colors.gray['200'],
        },
      }),
    },
    InputBase: {
      styles: () => ({
        input: {
          borderRadius: '12px',
          color: colors.primary['900'],
          height: '56px',
          paddingTop: '18px',
        }
      })
    },
    InputWrapper: {
      defaultProps: { inputWrapperOrder: ['label', 'input', 'error', 'description'] },
      styles: (theme) => ({
        root: {
          position: 'relative'
        },
        description: {
          color: theme.colors.gray[5],
          fontSize: '12px',
          fontWeight: 400,
          marginTop: '8px',
        },
        label: {
          paddingTop: '6px',
          position: 'absolute',
          color: 'rgba(0, 0, 0, 0.48)',
          fontSize: '14px',
          paddingLeft: '16px',
          // paddingTop: calc(var(--mantine-spacing-sm) / 2),
          zIndex: 1,
        },
      }),
    },
    Button: {
      defaultProps: {
        variant: 'filled',
        backgroundColor: '#0B7B94'
      },
      styles: () => ({
        root: {
          borderRadius: '16px',
          padding: '16px',
          height: 'auto'
        }
      }),
    },
    Stepper: {
      styles: () => ({
        verticalSeparator: {
          display: 'none'
        },
        stepIcon: {
          backgroundColor: '#6BB11D',
          borderColor: '#6BB11D',
          minHeight: '24px',
          minWidth: '24px',
          height: '24px',
          width: '24px'
        },
        stepBody: {
          textAlign: 'left'
        },
        stepWrapper: {
          display: 'none',
        },
        step: {
          transition: '0.3s ease-in-out',
          borderRadius: '24px',
          background: '#FFF',
          paddingTop: '24px',
          paddingBottom: '24px',
          paddingRight: '16px',
          paddingLeft: '16px',
          width: '100%',
          flexDirection: 'row-reverse',
          justifyContent: 'flex-end',
          marginTop: '16px',
          alignItems: 'center'
        },
        stepLabel: {
          color: '#5B6071'
        },
        stepDescription: {
          fontWeight: '400',
          color: '#5B6071'
        }
      }),
    },
    Modal: {
      defaultProps: { closeButtonProps: { iconSize: 24 } },
      styles: () => ({
        body: { overflowY: 'auto', padding: '24px' },
        content: { borderRadius: '30px', display: 'flex', flexDirection: 'column', padding: '20px' },
        header: {
          padding: '24px',
          paddingBottom: 0,
        },
        inner: { zIndex: 3000, paddingInline: 0 },
        overlay: { zIndex: 2000 },
        title: { fontSize: '18px', fontWeight: 600, width: '100%', color: '#228BE6' },
      }),
    },
    Navbar: { styles: () => ({ root: { padding: 0 } }) },
    PasswordInput: {
      styles: () => ({
        innerInput: { height: '100%' },
        // section: {
        //   left: '12px'
        // }
      })
    },
    Popover: {
      styles: () => ({
        dropdown: {
          'zIndex': '1001 !important',
        },
      }),
    },
    Accordion: {
      styles: () => ({
        item: {
          marginBottom: '24px',
          background: 'transparent',
        },
        control: {
          '&:hover': { borderRadius: '0.75rem !important' },
        },
        label: {
          padding: 0,
          color: 'white'
        }
      }),
    },
    RadioGroup: {
      styles: () => ({
        error: { marginLeft: '12px', marginTop: '12px' },
      }),
    },
    Table: {
      styles: () => ({
        table: {
          background: 'white',
          borderRadius: '12px'
        }
      }),
    },
    Select: {
      styles: () => ({
        input: {
          borderRadius: '12px',
          color: colors.primary['900'],
          height: '56px',
          paddingTop: '18px',
        }
      })
    },
    Checkbox: {
      styles: () => ({ icon: { width: '50%' }, inner: { marginRight: '2px' } }),
    },
    Switch: {
      styles: () => ({
        label: { color: '#6B7280', fontSize: '14px', fontWeight: 'normal' },
        thumb: {
          border: 'none'
        },
        track: {
          '&:checked': { background: '#5D70FF' },
          border: 'none',
          minWidth: 0,
        },
      }),
    },
    NavLink: {
      styles: () => ({
        children: { '.mantineNavLinkRoot': { paddingLeft: '3rem' } },
        root: {
          '&:hover': { textDecoration: 'none' },
          background: 'transparent',
          color: '#34495E'
        },
      }),
    },
    Dropzone: {
      styles: () => ({
        root: {
          borderColor: '#99B1E6',
          background: '#F5FAFE',
          padding: '16px 24px',
          borderImageWidth: 1,
          borderImageSlice: 2
        },
      }),
    },
    TimeInput: { styles: () => ({ input: { height: '31px !important' } }) },
    ActionIcon: { styles: () => ({ root: { color: '#8E8E93', '&:hover': { color: '#397BFF !important' }, } }) },
    Chip: {
      styles: () => ({
        iconWrapper: {
          display: 'none'
        }
      })
    },
  },
  fontFamily: 'Inter, sans-serif',
  fontFamilyMonospace: 'Inter, monospace',
  headings: {
    fontWeight: '600',
    sizes: {
      h1: { fontSize: '42px' },
      h2: { fontSize: '36px' },
      h3: { fontSize: '32px' },
      h4: { fontSize: '28px' },
      h5: { fontSize: '24px' },
      h6: { fontSize: '20px' },
    },
  },
  primaryColor: 'green',
  white: '#fff',
};
