export type ILocalization = 'ru' | 'kz';

export enum IEnumLocalization {
  kz = 'Казахский',
  ru = 'Русский',
}

export enum IEnumLocalizationKz {
  kz = 'Қазақша',
  ru = 'Орысша',
}
