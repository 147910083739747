import { FC, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { Link } from 'react-router-dom';

import { t } from 'i18next';

import {
  authInitial,
  authValidation,
  IUserAuthRequest,
  useAuth,
} from '@api/auth/useAuth';
import {
  IUserOtpConfirmRequest,
  otpConfirmInitial,
  otpConfirmValidation,
  useConfirmOtp,
} from '@api/auth/useConfirmOtp';
import { useOtpSend } from '@api/auth/useOtpSend';
import { PageMeta } from '@context/PageMetaContext';
import {
  Button,
  Divider,
  Image,
  InputBase,
  PinInput,
  Text,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconCheck } from '@tabler/icons-react';

const LoginPage: FC = () => {
  const login = useAuth();

  const smsConfirm = useConfirmOtp();
  const smsSend = useOtpSend();

  const [smsConfirmStep, setSmsConfirmStep] = useState(false);
  const [identifier, setIdentifier] = useState('');
  const [identifierType, setIdentifierType] = useState<'BIN' | 'IIN' | null>(
    null
  );

  const [otpSendPath, setOtpSendPath] = useState('');

  const form = useForm<IUserAuthRequest>({
    initialValues: authInitial,
    validate: yupResolver(authValidation),
  });

  const smsConfirmForm = useForm<IUserOtpConfirmRequest>({
    initialValues: otpConfirmInitial,
    validate: yupResolver(otpConfirmValidation),
  });

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (smsConfirmStep) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes(0);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  const handleSendCode = () => {
    smsSend
      .mutateAsync({
        identifier: identifier,
      })
      .then(() => {
        setMinutes(0);
        setSeconds(59);
        setSmsConfirmStep(true);
      })
      .catch((error) => {
        form.setFieldError('phone', t('auth.errors.userNotFound'));
      });
  };

  useEffect(() => {
    const idField = form.values.identifier;
    setIdentifier(idField);
    if (idField) {
      smsConfirmForm.setFieldValue('identifier', idField);
      if ([0, 1, 2, 3].includes(Number(idField[4]))) {
        setIdentifierType('IIN');
        form.setFieldValue('identifierType', 'IIN');
      } else if ([4, 5, 6].includes(Number(idField[4]))) {
        setIdentifierType('BIN');
        form.setFieldValue('identifierType', 'BIN');
      }
    }
  }, [form.values.identifier]);

  return (
    <>
      <PageMeta title="Логин" />

      <div className="flex w-full justify-between">
        <div className="my-auto hidden bg-[#F3F3F7] lg:block">
          <Image src="/images/illustrations/login-bg.svg" w={345} />
        </div>

        <div className="flex flex-col items-center justify-center w-full  lg:px-0">
          <a className="w-fit" href="https://dev.bcclife.xyz">
            <img
              alt="BCC Life logo"
              className="mb-10 h-8 object-contain !leading-none"
              src="/logo.png"
            />
          </a>
          <div className="flex bg-[#F3F3F7] w-full max-w-lg">
            <div className="w-full rounded-[24px] bg-white px-8 py-10">
              <h1 className="fw-[700] mb-6 text-center text-[32px]">
                {!smsConfirmStep ? t('auth.login') : t('auth.enterCode')}
              </h1>

              {/* FIXME: add back email or number */}
              {otpSendPath && (
                <p className="mx-auto w-[80%] text-center">
                  Мы выслали код на{' '}
                  {identifierType == 'BIN' ? 'вашу почту' : 'ваш номер'}{' '}
                  {otpSendPath.slice(0, 2) +
                    otpSendPath.slice(2).replace(/.(?=...)/g, '*')}
                </p>
              )}
              {!smsConfirmStep ? (
                <form
                  className="w-full"
                  onSubmit={form.onSubmit(({ identifier }) => {
                    login
                      .mutateAsync({
                        identifier: identifier.trim(),
                        identifierType: identifierType,
                      })
                      .then((result) => {
                        setSmsConfirmStep(true);
                        setOtpSendPath(String(result.data));
                      })
                      .catch((error) => {
                        if (
                          error.response.data.errorCode ==
                          'USER_EXISTS_BY_PHONE'
                        ) {
                          form.setFieldError(
                            'phone',
                            t('auth.errors.USER_EXISTS_BY_PHONE')
                          );
                        } else if (
                          error.response.data.errorCode == 'USER_NOT_FOUND'
                        ) {
                          form.setFieldError(
                            'phone',
                            t('auth.errors.USER_NOT_FOUND')
                          );
                        } else if (
                          error.response.data.errorCode ==
                          'USER_EXISTS_BY_IDENTIFIER'
                        ) {
                          form.setFieldError(
                            'identifier',
                            'Пользователь с введеным ИИН не найден. Зарегистрируйтесь.'
                          );
                        } else if (
                          error.response.data.errorCode == 'USER_BLOCKED'
                        ) {
                          form.setFieldError(
                            'phone',
                            t('auth.errors.USER_BLOCKED')
                          );
                        }
                      });
                  })}
                >
                  <InputBase
                    component={IMaskInput}
                    label={t('auth.iin')}
                    mask="000000 000000"
                    name="identifier"
                    placeholder="XXXXXX XXXXXX"
                    rightSection={
                      form.values.identifier ? (
                        <IconCheck className="mr-4" color="#27ae60" />
                      ) : (
                        <></>
                      )
                    }
                    variant="filled"
                    {...form.getInputProps('identifier')}
                  />

                  {/* <Text c="dimmed" mt={8} size="sm">
                  {identifierType == 'IIN'
                    ? t('auth.otpMessage')
                    : t('auth.emailOtpMessage')}
                </Text> */}
                  <Divider className="my-4" />
                  <div className="flex flex-col">
                    <Button
                      color="#0B7B94"
                      disabled={!form.values.identifier}
                      loading={login.isLoading}
                      type="submit"
                    >
                      {t('auth.getCode')}
                    </Button>
                  </div>
                  <div className="mt-5 flex flex-col items-end justify-between">
                    <Text className="mb-6 w-full text-start text-sm ">
                      {t('auth.noAccount')}
                      <Link
                        className="font-normal text-[#0B7B94]"
                        to="/register"
                      >
                        {' '}
                        {t('auth.register')}
                      </Link>
                    </Text>
                  </div>
                </form>
              ) : (
                <form
                  className="w-full justify-center"
                  onSubmit={smsConfirmForm.onSubmit(({ otp }) => {
                    smsConfirm
                      .mutateAsync({
                        identifier: identifier.trim() ?? null,
                        otp: otp.trim(),
                      })
                      .catch((error) => {
                        if (error.response.data.errorCode == 'INCORRECT_CODE') {
                          form.setFieldError(
                            'otp',
                            t('auth.errors.otpCodeNotValid')
                          );
                        }
                      });
                  })}
                >
                  <div className="flex justify-center mt-5">
                    <PinInput
                      className="mx-auto"
                      oneTimeCode
                      placeholder=""
                      size="md"
                      type="number"
                      {...smsConfirmForm.getInputProps('otp')}
                    />
                  </div>
                  {/* {smsConfirmForm.errors && (
  <Text c="dimmed" mt={8} size="sm">
    {t('auth.errors.otpCodeNotValid')}
  </Text>
  )} */}
                  {/* <Text c="dimmed" mt={8} size="sm">
    {identifierType == 'IIN'
      ? t('auth.otpMessage')
      : t('auth.emailOtpMessage')}
  </Text> */}
                  <div className="mt-8 flex flex-col">
                    <Button
                      className="mx-auto mb-4 mt-4 border-2 md:px-8"
                      color="#0B7B94"
                      disabled={seconds != 0}
                      onClick={handleSendCode}
                      size="md"
                    >
                      Запросить код повторно через {minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds} секунд
                    </Button>

                    <Button
                      className="bg-color-[#11122C]"
                      color="#0B7B94"
                      loading={smsConfirm.isLoading}
                      type="submit"
                    >
                      {t('auth.getCode')}
                    </Button>
                  </div>
                </form>

              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
