import { AxiosError, AxiosResponse } from 'axios';
import { Maybe, object, ObjectSchema, string } from 'yup';

import { useMutation } from '@tanstack/react-query';
import { api } from '@utils/index';
import { notify } from '@utils/notify';

import { IBackendError } from '@/types/common/IBackendError';

export type IUserAuthRequest = {
  identifier: string;
  identifierType: 'IIN' | 'BIN' | null;
};

export const authInitial: IUserAuthRequest = {
  identifier: '',
  identifierType: null,
};

export const authValidation: ObjectSchema<IUserAuthRequest> = object().shape({
  identifier: string()
    .required()
    .min(13, 'ИИН/БИН должен быть не менее 12 символов')
    .max(13, 'ИИН/БИН должен быть не более 12 символов'),
  identifierType: string<'IIN' | 'BIN'>().required(),
});

export function useAuth() {
  return useMutation<
    AxiosResponse<string>,
    AxiosError<Maybe<IBackendError>>,
    IUserAuthRequest
  >((payload) => api.post('/user-service/api/auth/login-by-otp', payload), {
    onError: (error) => {
      notify('error', error.message);
    },
  });
}
