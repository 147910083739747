import { IPageActions } from '@/types/common/IPageActions';

enum IEnumContractsPageActions {
  'delete',
}

export type IContractsPageActions = {
  action: keyof typeof IEnumContractsPageActions;
  actionId: string;
};

export const contractsPageActions: IPageActions<IContractsPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumContractsPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
