import { FC, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { Link } from 'react-router-dom';

import { t } from 'i18next';

import {
  IUserOtpConfirmRequest,
  otpConfirmInitial,
  otpConfirmValidation,
  useConfirmOtp,
} from '@api/auth/useConfirmOtp';
import { useOtpSend } from '@api/auth/useOtpSend';
import {
  IUserRegisterRequest,
  registerInitial,
  registerValidation,
  useRegister,
} from '@api/auth/useRegister';
import { PageMeta } from '@context/PageMetaContext';
import {
  Button,
  Checkbox,
  Divider,
  Image,
  InputBase,
  PinInput,
  Text,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconCheck } from '@tabler/icons-react';

const RegisterPage: FC = () => {
  const register = useRegister();

  const otpConfirm = useConfirmOtp();
  const otpSend = useOtpSend();

  const [agreement, setAgreement] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [otpConfirmStep, setOtpConfirmStep] = useState(false);
  const [identifierType, setIdentifierType] = useState<'BIN' | 'IIN' | null>(
    null
  );

  const form = useForm<IUserRegisterRequest>({
    initialValues: registerInitial,
    validate: yupResolver(registerValidation),
  });

  const otpConfirmForm = useForm<IUserOtpConfirmRequest>({
    initialValues: otpConfirmInitial,
    validate: yupResolver(otpConfirmValidation),
  });

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (otpConfirmStep) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  const handleSendCode = () => {
    otpSend
      .mutateAsync({
        identifier: identifier,
      })
      .then(() => {
        setMinutes(0);
        setSeconds(59);
        setOtpConfirmStep(true);
      })
      .catch((error) => {
        form.setFieldError('phone', t('auth.errors.otpCodeNotValid'));
      });
  };

  useEffect(() => {
    const idField = form.values.identifier;
    setIdentifier(String(idField));
    if (idField) {
      if ([0, 1, 2, 3].includes(Number(idField[4]))) {
        setIdentifierType('IIN');
        form.setFieldValue('identifierType', 'IIN');
        otpConfirmForm.setFieldValue('identifier', idField);
      } else if ([4, 5, 6, 7, 8, 9].includes(Number(idField[4]))) {
        setIdentifierType('BIN');
        form.setFieldValue('identifierType', 'BIN');
        otpConfirmForm.setFieldValue('identifier', idField);
      }
    }
  }, [form.values.identifier]);

  return (
    <>
      <PageMeta title="Регистрация" />

      <div className="flex justify-between md:w-auto lg:w-full ">
        <div className="my-auto hidden bg-[#F3F3F7] lg:block ">
          <Image src="/images/illustrations/login-bg.svg" w={345} />
        </div>

        <div className="flex flex-col items-center justify-center">
          <a className="w-fit" href="https://dev.bcclife.xyz">
            <img
              alt="BCC Life logo"
              className="mb-10 h-8 object-contain !leading-none"
              src="/logo.png"
            />
          </a>
          <div className="flex bg-[#F3F3F7]">
            <div className="max-w-[620px] rounded-[24px] bg-white px-12 py-10 min-w-[auto]">
              <h1 className="fw-[700] mb-5 text-center text-auto md:text-[32px]">
                {t('auth.register')}
              </h1>
              {!otpConfirmStep ? (
                <form
                  className="w-full"
                  onSubmit={form.onSubmit(({ email, phone }) => {
                    setPhoneNumber(`+${phone?.replace(/\D/g, '')}` as string);
                    setEmail(email as string);
                    register
                      .mutateAsync({
                        email:
                          identifierType == 'BIN' && email
                            ? String(email).trim()
                            : null,
                        identifier: form.values.identifier,
                        identifierType: identifierType,
                        phone:
                          identifierType == 'IIN' &&
                            `+${phone?.replace(/\D/g, '')}`
                            ? String(`+${phone?.replace(/\D/g, '')}`).trim()
                            : null,
                      })
                      .then(() => {
                        setOtpConfirmStep(true);
                      })
                      .catch((error) => {
                        if (
                          error.response.data.errorCode ==
                          'USER_EXISTS_BY_PHONE'
                        ) {
                          form.setFieldError(
                            'phone',
                            t('auth.errors.USER_EXISTS_BY_PHONE')
                          );
                        } else if (
                          error.response.data.errorCode == 'USER_NOT_FOUND'
                        ) {
                          form.setFieldError(
                            'phone',
                            t('auth.errors.USER_NOT_FOUND')
                          );
                        } else if (
                          error.response.data.errorCode ==
                          'USER_EXISTS_BY_IDENTIFIER'
                        ) {
                          form.setFieldError(
                            'identifier',
                            t('auth.errors.USER_EXISTS_BY_IDENTIFIER')
                          );
                        } else if (
                          error.response.data.errorCode == 'USER_BLOCKED'
                        ) {
                          form.setFieldError(
                            'phone',
                            t('auth.errors.USER_BLOCKED')
                          );
                        }
                      });
                  })}
                >
                  <InputBase
                    component={IMaskInput}
                    label={t('auth.iin')}
                    mask="000000 000000"
                    name="identifier"
                    placeholder="XXXXXX XXXXXX"
                    rightSection={
                      form.values.identifier ? (
                        <IconCheck className="mr-4" color="#27ae60" />
                      ) : (
                        <></>
                      )
                    }
                    withAsterisk
                    {...form.getInputProps('identifier')}
                  />
                  {identifierType &&
                    (identifierType == 'BIN' ? (
                      <InputBase
                        className="mt-5"
                        label={t('auth.email')}
                        name="email"
                        placeholder="example@gmail.com"
                        rightSection={
                          form.values.email ? (
                            <IconCheck className="mr-4" color="#27ae60" />
                          ) : (
                            <></>
                          )
                        }
                        withAsterisk
                        {...form.getInputProps('email')}
                      />
                    ) : (
                      <InputBase
                        className="mt-5"
                        component={IMaskInput}
                        label={t('auth.phoneNumber')}
                        mask="+7 (000) 000-00-00"
                        name="phone"
                        placeholder="+7 (XXX) XXX-XX-XX"
                        rightSection={
                          form.values.phone ? (
                            <IconCheck className="mr-4" color="#27ae60" />
                          ) : (
                            <></>
                          )
                        }
                        withAsterisk
                        {...form.getInputProps('phone')}
                      />
                    ))}

                  {/* <Text c="dimmed" mt={8} size="sm">
                  {identifierType == 'IIN'
                    ? t('auth.otpMessage')
                    : t('auth.emailOtpMessage')}
                </Text> */}
                  <Divider className="my-4" />
                  <Checkbox
                    checked={agreement}
                    className="mt-8"
                    color="#0B7B94"
                    label={
                      <>
                        С условиями{' '}
                        <a href="/agreement">Пользовательского соглашения</a>{' '}
                        ознакомлен(а) и согласен(а){' '}
                        <a>
                          на сбор, обработку и использование моих персональных
                          данных
                        </a>
                      </>
                    }
                    onChange={(event) =>
                      setAgreement(event.currentTarget.checked)
                    }
                  />
                  <div className="mt-8 flex flex-col">
                    <Button
                      className="bg-color-[#11122C]"
                      color="#0B7B94"
                      disabled={!agreement}
                      loading={register.isLoading}
                      type="submit"
                    >
                      {t('auth.getSms')}
                    </Button>
                  </div>

                  <div className="mt-5 flex flex-col items-end justify-between">
                    <Text className="mb-6 w-full text-start text-sm ">
                      {t('auth.haveAnAccount')}
                      <Link className="font-normal text-[#0B7B94]" to="/login">
                        {' '}
                        {t('auth.enter')}
                      </Link>
                    </Text>
                  </div>
                </form>
              ) : (
                <form
                  className="w-full"
                  onSubmit={otpConfirmForm.onSubmit(({ identifier, otp }) => {
                    otpConfirm
                      .mutateAsync({
                        identifier,
                        otp: otp.trim(),
                      })
                      .catch(() => {
                        form.setFieldError(
                          'otp',
                          t('auth.errors.otpCodeNotValid')
                        );
                      });
                  })}
                >
                  <PinInput
                    className="mt-5 items-center"
                    placeholder=""
                    size="md"
                    type="number"
                    {...otpConfirmForm.getInputProps('otp')}
                  />
                  <Text c="dimmed" mt={8} size="sm">
                    {identifierType == 'IIN'
                      ? t('auth.otpMessage')
                      : t('auth.emailOtpMessage')}
                  </Text>
                  <div className="mt-8 flex flex-col">
                    <Button
                      className="bg-color-[#11122C]"
                      loading={otpConfirm.isLoading}
                      type="submit"
                    >
                      {t('auth.login')}
                    </Button>

                    <Button
                      disabled={seconds != 0}
                      mt={12}
                      onClick={handleSendCode}
                      variant="outline"
                    >
                      Отправить код повторно
                    </Button>
                    {minutes === 0 && seconds === 0 ? null : (
                      <Text c="dimmed" mt={10} size="xs">
                        Отправить код повторно через {minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </Text>
                    )}
                  </div>
                  <Divider className="my-[48px]" />
                  <div className="flex justify-between">
                    <Link to="/login">{t('auth.haveAnAccount')}</Link>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
