import { AxiosError } from 'axios';

import { ApplicationsStatusEnumKeys } from '@consts/enums/applicationsStatusEnum';
import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IApplication } from '@/types/IApplication';

type IStatementsResponse = IPageAbleResponse<IApplication>;

export function useStatements({
  ...params
}: IPageAbleRequest & {
  status?: ApplicationsStatusEnumKeys;
  type: 'INDIVIDUAL' | 'COMPANY';
}) {
  return useQuery<IStatementsResponse, AxiosError>(
    [queryKeys.statements, params],
    () =>
      api
        .get(`/contract-service/api/statements/get-all-statements`, { params })
        .then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess) && !!params.type,
    }
  );
}
