export const queryKeys = {
  companyContracts: 'companyContracts',
  contract: 'contract',
  contracts: 'contracts',
  file: 'file',
  statement: 'statement',
  statements: 'statements',
  tokenAccess: 'tokenAccess',
  tokenRefresh: 'tokenRefresh',
  user: 'user',
};
