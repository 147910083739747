import { AxiosError, AxiosResponse } from 'axios';

import { ApplicationsStatusEnumKeys } from '@consts/enums/IEnumApplicationsStatus';
import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@utils/index';
type IStatementUpdateResponse = void;

export function useStatementChangeStatus({
  ...params
}: {
  comment?: string;
  id: number;
  status: ApplicationsStatusEnumKeys;
}) {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<IStatementUpdateResponse>, AxiosError>(
    () => api.put(`/contract-service/api/admin/statements`, {}, { params }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.statements]),
          queryClient.invalidateQueries([queryKeys.statement]);
      },
    }
  );
}
