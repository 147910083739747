import { AxiosError, AxiosResponse } from 'axios';
import { Maybe, object, ObjectSchema, string } from 'yup';

import { useMutation } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IBackendError } from '@/types/common/IBackendError';
import { IAuth } from '@/types/IAuth';

export type IUserRegisterRequest = {
  email?: string | null;
  identifier: string | null;
  identifierType: 'IIN' | 'BIN' | null;
  phone?: string | null;
};

export const registerInitial: IUserRegisterRequest = {
  email: null,
  identifier: null,
  identifierType: null,
  phone: null,
};

export const registerValidation: ObjectSchema<IUserRegisterRequest> =
  object().shape({
    email: string()
      .email('Проверьте правильность введенного email-а')
      .when('category', {
        is: (identifierType: 'IIN' | 'BIN') => identifierType == 'BIN',
        otherwise: (s) => s.notRequired(),
        then: (s) => s.required('Поле обязательно для заполнения'),
      }),
    identifier: string()
      .required('Поле обязательно для заполнения')
      .min(13, 'ИИН/БИН должен быть не менее 12 символов')
      .max(13, 'ИИН/БИН должен быть не более 12 символов'),
    identifierType: string<'IIN' | 'BIN'>().required(),
    phone: string().when('category', {
      is: (identifierType: 'IIN' | 'BIN') => identifierType == 'IIN',
      otherwise: (s) => s.notRequired(),
      then: (s) => s.required('Поле обязательно для заполнения'),
    }),
  });

export function useRegister() {
  return useMutation<
    AxiosResponse<IAuth>,
    AxiosError<Maybe<IBackendError>>,
    IUserRegisterRequest
  >((payload) => api.post('/user-service/api/auth/registration', payload), {});
}
