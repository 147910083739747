'use client';
import { ReactNode, useState } from 'react';

import parse from 'html-react-parser';
import { t } from 'i18next';

import {
  Accordion,
  AccordionControl,
  AccordionItem,
  AccordionPanel,
  Button,
  Divider,
  Text,
} from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';

// import Image from '../../../node_modules/next/image';
// import logoImg from '../../../public/images/bcc-logo-white.svg';
// import { Link } from '../../utils/navigation';
import { DeepKey } from '@/types/common/DeepKey';
import { ILocaleMessages } from '@/types/common/ILocaleMessages';
// import { ContactUsModal } from './modals/ContactUsModal';

type IFaq = {
  questions: { description: ReactNode; question: DeepKey<ILocaleMessages> }[];
  title: DeepKey<ILocaleMessages>;
};

export default function AuthFooter() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const faqs: IFaq[] = [
    {
      questions: [
        {
          description: (
            <div className="text-base font-normal leading-7">
              <span>{parse(t('translation.footer.address'))}</span>
            </div>
          ),
          question: 'translation.footer.contacts',
        },
        {
          description: (
            <div className="grid gap-6">
              <div className="flex flex-col gap-1">
                <p className="m-0 text-sm font-bold">
                  {t('footer.forClients')}
                </p>
                <a
                  className="!text-white"
                  href={`${process.env.REACT_APP_LANDING_URL}/life-insurance/`}
                >
                  {t('translation.footer.lifeInsurance')}
                </a>
              </div>

              <div className="flex flex-col gap-1">
                <p className="m-0 text-sm font-bold">
                  {t('translation.footer.forBusiness')}
                </p>
                <a
                  className="!text-white"
                  href={`${process.env.REACT_APP_LANDING_URL}/employee-insurance/`}
                >
                  {t('translation.footer.employeeInsurance')}
                </a>
              </div>
            </div>
          ),
          question: 'translation.footer.products',
        },
        {
          description: (
            <div className="grid grid-rows-7 gap-3">
              <a
                className="!text-white"
                href={`${process.env.REACT_APP_LANDING_URL}/about/`}
              >
                {t('translation.footer.aboutCompany')}
              </a>

              <a
                className="!text-white"
                href={`${process.env.REACT_APP_LANDING_URL}/management/`}
              >
                {t('translation.footer.management')}
              </a>

              <a
                className="!text-white"
                href={`${process.env.REACT_APP_LANDING_URL}/documents/`}
              >
                {t('translation.footer.documents')}
              </a>

              <a
                className="!text-white"
                href={`${process.env.REACT_APP_LANDING_URL}/financial-literacy/`}
              >
                {t('translation.footer.financialLiteracy')}
              </a>

              <a
                className="!text-white"
                href={`${process.env.REACT_APP_LANDING_URL}/news/`}
              >
                {t('translation.footer.news')}
              </a>

              <a
                className="!text-white"
                href={`${process.env.REACT_APP_LANDING_URL}/vacancy/`}
              >
                {t('translation.footer.vacancies')}
              </a>
            </div>
          ),
          question: 'translation.footer.siteMap',
        },
        {
          description: (
            <div className="grid grid-rows-3 gap-3">
              <a className="!text-white">
                {t('translation.footer.privacyPolicy')}
              </a>
              <a className="!text-white">{t('translation.footer.cookie')}</a>
              <a className="!text-white">
                {t('translation.footer.termsOfService')}
              </a>
            </div>
          ),
          question: 'translation.footer.legal',
        },
        {
          description: (
            <div className="grid grid-rows-1 gap-3">
              <a
                className="!text-white"
                href="https://id.mkb.kz/"
                rel="noreferrer"
                target="_blank"
              >
                {t('translation.footer.checkInsurancePolicy')}
              </a>
            </div>
          ),
          question: 'translation.footer.services',
        },
      ],
      title: 'translation.footer.legal',
    },
  ];

  return (
    <>
      {/* <ContactUsModal isOpen={isOpen} onClose={handleClose} /> */}
      <div className="hidden grid-cols-4 items-center gap-[102px] rounded-t-[42px] bg-white px-[120px] py-8 md:grid">
        <h5>{t('footer.haveQuestions')}</h5>
        <Button
          className="w-fit rounded-[8px] border-[#D1D5E5] px-4 py-1 text-sm font-normal"
          color="gray"
          //   leftSection={<TelegramIcon className="h-6 w-6" />}
          variant="outline"
        >
          {t('footer.telegramSupport')}
        </Button>
        <div>
          <h5>
            <a href="tel:7878">7878</a>
          </h5>
          <span className="text-base text-[#9DA1AE]">
            {' '}
            {t('footer.callCenter')}
          </span>
        </div>
        <Button
          className="w-fit rounded-[16px] px-4 py-2 text-base font-normal"
          color="yellow"
          onClick={() => setIsOpen(true)}
        >
          {t('footer.contactUs')}
        </Button>
      </div>
      <footer className="bg-[#2C3E50] px-5 pb-12 pt-4 text-[#EDEDED] md:px-0 md:py-[42px]">
        <div className="mx-auto gap-6 px-0 md:px-[75px] md:pt-8">
          <div className="hidden grid-cols-1 gap-16 md:grid md:grid-cols-2 md:grid-cols-4">
            <div>
              <h6 className="mb-4 text-[#0B7B94]">{t('footer.contacts')}</h6>
              <span className="text-base font-normal leading-7">
                {parse(t('footer.address'))}
              </span>
            </div>

            <div>
              <h6 className="text-[#0B7B94]">{t('footer.products')}</h6>
              <div className="mt-4 grid gap-6">
                <div className="flex flex-col gap-1">
                  <p className="m-0 text-sm font-bold">
                    {t('footer.forClients')}
                  </p>
                  <a
                    className="!text-white"
                    href={`${process.env.REACT_APP_LANDING_URL}/life-insurance/`}
                  >
                    {t('footer.lifeInsurance')}
                  </a>
                </div>

                <div className="flex flex-col gap-1">
                  <p className="m-0 text-sm font-bold">
                    {t('footer.forBusiness')}
                  </p>
                  <a
                    className="!text-white"
                    href={`${process.env.REACT_APP_LANDING_URL}/employee-insurance/`}
                  >
                    {t('footer.employeeInsurance')}
                  </a>
                </div>
              </div>
            </div>

            <div>
              <h6 className="text-[#0B7B94]">{t('footer.siteMap')}</h6>
              <div className="mt-4 grid grid-rows-7 gap-3">
                <a
                  className="!text-white"
                  href={`${process.env.REACT_APP_LANDING_URL}/about/`}
                >
                  {t('footer.aboutCompany')}
                </a>

                <a
                  className="!text-white"
                  href={`${process.env.REACT_APP_LANDING_URL}/management/`}
                >
                  {t('footer.management')}
                </a>

                <a
                  className="!text-white"
                  href={`${process.env.REACT_APP_LANDING_URL}/documents/`}
                >
                  {t('footer.documents')}
                </a>

                <a
                  className="!text-white"
                  href={`${process.env.REACT_APP_LANDING_URL}/financial-literacy/`}
                >
                  {t('footer.financialLiteracy')}
                </a>

                <a
                  className="!text-white"
                  href={`${process.env.REACT_APP_LANDING_URL}/news/`}
                >
                  {t('footer.news')}
                </a>

                <a
                  className="!text-white"
                  href={`${process.env.REACT_APP_LANDING_URL}/vacancy/`}
                >
                  {t('footer.vacancies')}
                </a>
              </div>
            </div>

            <div className="flex flex-col gap-10">
              <div className="h-fit">
                <h6 className="text-[#0B7B94]">{t('footer.legal')}</h6>
                <div className="mt-4 grid grid-rows-3 gap-3">
                  <a className="!text-white">{t('footer.privacyPolicy')}</a>
                  <a className="!text-white">{t('footer.cookie')}</a>
                  <a className="!text-white">{t('footer.termsOfService')}</a>
                </div>
              </div>

              <div>
                <h6 className="text-[#0B7B94]">{t('footer.services')}</h6>
                <div className="mt-4 grid grid-rows-1 gap-3">
                  <a
                    className="!text-white"
                    href="https://id.mkb.kz/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('footer.checkInsurancePolicy')}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="block md:hidden">
            {faqs.map((footerItem) => (
              <div key={footerItem.title}>
                <FooterAccordion footerItem={footerItem} t={t} />
              </div>
            ))}
          </div>

          <Divider className="my-8" color="#B3B8C7" />
          <div className="grid items-center gap-2 md:grid-cols-7 md:gap-5">
            <div className="col-span-1 !text-sm">
              <Text className="text-[#0B7B94]">© 2024 АО BCC Life</Text>
              <span className="">{t('footer.allRightsReserved')}</span>
            </div>

            <p className="col-span-5 justify-center text-sm">
              {t('footer.license')}
            </p>

            {/* <Link className="flex justify-start md:justify-end" href="/">
              <Image
                alt="bcc-life"
                className="object-contain"
                height={30}
                priority
                src={logoImg}
                width={115}
              />
            </Link> */}
          </div>
        </div>
      </footer>
    </>
  );
}

export function FooterAccordion({
  classNames,
  footerItem,
  t,
}: {
  classNames?: string;
  footerItem: IFaq;
  t: (string: DeepKey<ILocaleMessages>) => string;
}) {
  return (
    <Accordion
      chevron={<IconChevronDown color="#707c88" stroke={1.2} />}
      classNames={{
        chevron: 'rounded-full font-normal w-6 p-2 text-white',
        content: 'pt-5',
        item: `${classNames} bg-transparent px-3 mt-3 border-b border-b-[#707c88] data-[active]:border-none`,
        label: 'font-medium p-0 mr-5 text-white',
        panel: 'text-sm overflow-hidden',
        root: 'rounded-2xl overflow-hidden',
      }}
      variant="separated"
    >
      {footerItem.questions.map(({ description, question }) => (
        <AccordionItem key={question} value={question}>
          <AccordionControl>{t(question)}</AccordionControl>
          <AccordionPanel>{description}</AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}
