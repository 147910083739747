import { FC, useState } from 'react';

import { t } from 'i18next';

import { useStatements } from '@api/applications/useStatements';
import { useCurrent } from '@api/auth/useCurrent';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import {
  FloatingIndicator,
  Pagination,
  Skeleton,
  UnstyledButton,
} from '@mantine/core';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import ApplicationCard from '../components/molecules/ApplicationCard';
import { statementsFilters } from '../consts/filters/statementsFilters';
import {
  IStatementPageActions,
  statementPageActions,
} from '../consts/pageActions/IStatementPageActions';

const data = [
  {
    label: 'Активные',
    value: 'active',
  },
  {
    label: 'Все',
    value: 'all',
  },
];

const ApplicationsPage: FC = () => {
  const meQuery = useCurrent();

  const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);
  const [controlsRefs, setControlsRefs] = useState<
    Record<string, HTMLButtonElement | null>
  >({});
  const [active, setActive] = useState(0);

  const { filtered, onFilter } = useUrlFilteredActioned<
    IPageAbleRequest,
    IStatementPageActions
  >(statementsFilters, statementPageActions);

  const statementsQuery = useStatements({
    ...filtered,
    size: 2,
    status: active == 0 ? 'IN_PROCESSING_DOCUMENTS' : undefined,
    type: meQuery.data?.data.identifierType == 'IIN' ? 'INDIVIDUAL' : 'COMPANY',
  });

  const statements = statementsQuery.data?.content;

  const setControlRef = (index: number) => (node: HTMLButtonElement) => {
    controlsRefs[index] = node;
    setControlsRefs(controlsRefs);
  };

  const controls = data.map((item, index) => (
    <UnstyledButton
      className="control w-[50%] px-4 text-center data-[active]:text-[#1C1C1E]"
      key={item.value}
      mod={{ active: active === index }}
      onClick={() => setActive(index)}
      ref={setControlRef(index)}
    >
      <span className="controlLabel">{item.label}</span>
    </UnstyledButton>
  ));


  return (
    <div>
      <PageMeta
        breadcrumbs={[
          { link: '/applications', title: t('applications.applications') },
        ]}
        openMenuKeys={['applications']}
        selectedMenuKeys={['applications']}
        title={t('applications.applications')}
      />

      <div className="flex h-[280px] rounded-b-[32px] bg-[#DEEFF9]">
        <div className="application-width mx-auto my-auto flex flex-col md:flex-row md:items-center md:justify-between">
          <h1 className="font-bold text-[#1C1C1E] text-center md:text-left md:mx-0 ">Страхование жизни</h1>

          <div className="root flex mt-4 relative w-full md:w-[280px]" ref={setRootRef}>
            {controls}

            <FloatingIndicator
              className="indicator absolute bottom-0 left-1/2 transform -translate-x-1/2 md:static md:transform-none md:translate-none "
              parent={rootRef}
              target={controlsRefs[active]}
            />
          </div>
        </div>
      </div>


      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-3">
          {statementsQuery.isLoading ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : !statements ? (
            <NotFoundResult subTitle={t('applications.notFound')} title="" />
          ) : (
            <div className="relative bottom-[70px] flex flex-col justify-center gap-6 md:flex-row">
              {statements.slice(0, 2).map((application) => (
                <ApplicationCard
                  application={application}
                  key={application.id}
                />
              ))}
            </div>
          )}
          {statements && statements.length !== 0 && (
            <div className="application-width mx-auto mb-10 flex justify-end gap-4">
              <Pagination
                classNames={{
                  control:
                    'border-gray-200 border-[#DEE2E6] data-[active=true]:border-primary data-[active=true]:text-white',
                }}
                onChange={(page) => onFilter({ page: page - 1 })}
                total={statementsQuery.data?.totalPages || 1}
                value={
                  typeof filtered.page == 'string'
                    ? Number.parseInt(filtered.page) + 1
                    : Number(filtered.page) + 1 || 1
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationsPage;
