import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { t } from 'i18next';

import { useStatementById } from '@api/applications/useStatementById';
import { useCurrent } from '@api/auth/useCurrent';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { PageMeta } from '@context/PageMetaContext';
import { Avatar, Button, Skeleton } from '@mantine/core';

const ContractDetailsPage: FC = () => {
  const meQuery = useCurrent();
  const { id = '' } = useParams<{ id: string }>();

  const statementQuery = useStatementById({
    id: +id,
  });

  const statement = statementQuery.data?.data;
  const [active, setActive] = useState(1);

  return (
    <div className="application-width mx-auto flex">
      <PageMeta
        breadcrumbs={[{ link: '/contracts', title: t('contracts.contracts') }]}
        openMenuKeys={['contracts']}
        selectedMenuKeys={['contracts']}
        title={t('contracts.contracts')}
      />
      {statementQuery.isLoading ? (
        <Skeleton />
      ) : !statement ? (
        <NotFoundResult />
      ) : (
        <div className="my-8 grid grid-cols-9 gap-5">
          <div className="col-span-6 grid gap-6">asdasd</div>

          <div className="col-span-3">
            <div className="rounded-[32px] bg-white p-[30px]">
              <div className="flex items-center">
                <Avatar h={70} w={70} />
                <div className="flex flex-col">
                  <span className="text-base text-[#676971]">
                    Нужна помощь?
                    <br />
                    Вы можете связаться с нами
                  </span>
                  <a>7878</a>
                </div>
              </div>
              <Button className="mt-8 w-full">Страховой случай</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractDetailsPage;
