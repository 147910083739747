import { AxiosError, AxiosResponse } from 'axios';
import { Maybe, object, ObjectSchema, string } from 'yup';

import { storageKeys } from '@consts/storageKeys';
import { useMutation } from '@tanstack/react-query';
import { api } from '@utils/index';
import { notify } from '@utils/notify';

import { IBackendError } from '@/types/common/IBackendError';
import { IAuth } from '@/types/IAuth';

export type IUserAuthRequest = {
  refreshToken: string;
};

export const authInitial: IUserAuthRequest = {
  refreshToken: '',
};

export const authValidation: ObjectSchema<IUserAuthRequest> = object().shape({
  refreshToken: string().required(),
});

export function useRefreshToken() {
  return useMutation<
    AxiosResponse<IAuth>,
    AxiosError<Maybe<IBackendError>>,
    IUserAuthRequest
  >((payload) => api.post('/user-service/api/auth/refresh-token', payload), {
    onError: (error) => {
      notify('error', error.message);
    },
    onSuccess: (response) => {
      if (response.data.access_token) {
        localStorage.setItem(
          storageKeys.tokenAccess,
          response.data.access_token
        );
        localStorage.setItem(
          storageKeys.tokenRefresh,
          response.data.refresh_token
        );
      }
    },
  });
}
