import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import moment from 'moment';

import StatementIcon from '@components/atoms/icons/StatementIcon';
import { Button, Text, Tooltip } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';

import { IApplication } from '@/types/IApplication';

import { ApplicationStatusTag } from '../atoms/ApplicationStatusTag';

export default function ApplicationCard({
  application,
}: {
  application: IApplication;
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return (
    <div className="delay-80 flex max-w-[590px] flex-col justify-between rounded-[32px] bg-white p-8 shadow-sm transition ease-in-out hover:shadow-md">
      <div className="flex w-full items-center gap-6">
        <StatementIcon stroke="1.2" style={{ height: '55px', width: '55px' }} />
        <Tooltip
          label={
            language == 'kz' ? application.statementKz : application.statementRu
          }
        >
          <Text className="truncate" color="#495057" fw={800} size="lg">
            {application.status == 'IN_PROCESSING_DOCUMENTS'
              ? 'Извещение'
              : 'Заявление'}{' '}
            {application.statementNumber}
          </Text>
        </Tooltip>
      </div>

      <div className="grid grid-cols-1 gap-3 py-8 sm:grid-cols-2">
        <div className="grid items-baseline ">
          <Text color="#6D758F" fw={400} size="sm">
            Номер договора:
          </Text>
          <Text color="#6D758F" fw={600} size="sm">
            {application.contractNumber}
          </Text>
        </div>

        <div>
          <Text color="#6D758F" fw={400} size="sm">
            Cтатус:
          </Text>
          <ApplicationStatusTag status={application.status} />
        </div>

        <div>
          <Text color="#6D758F" fw={400} size="sm">
            Застрахованный:
          </Text>
          <Text color="#464C5D" fw={500} size="md">
            {application.acquirer || '-'}
          </Text>
        </div>

        <div>
          <Text color="#6D758F" fw={400} size="sm">
            Выгодоприобретатель:
          </Text>
          <Text color="#464C5D" fw={500} size="md">
            {application.beneficiary || '-'}
          </Text>
        </div>

        <div>
          <Text color="#6D758F" fw={400} size="sm">
            Страховая сумма:
          </Text>
          <Text color="#464C5D" fw={500} size="md">
            {application.insuranceAmount || '-'}
          </Text>
        </div>

        <div>
          <Text color="#6D758F" fw={400} size="sm">
            Страховая премия:
          </Text>
          <Text color="#464C5D" fw={500} size="md">
            {application.insurancePremium || '-'}
          </Text>
        </div>

        <div className="items-center">
          <Text color="#6D758F" fw={400} size="sm">
            Дата и время заявления:
          </Text>
          <Text color="#6D758F" fw={400} size="sm">
            {moment(application.createdAt).format('DD MMMM YYYY, HH:mm')}
          </Text>
        </div>

        <div className="items-center">
          <Text color="#6D758F" fw={400} size="sm">
            Дата и время последнего обн.:
          </Text>
          <Text color="#6D758F" fw={400} size="sm">
            {moment(application.updatedAt).format('DD MMMM YYYY, HH:mm')}
          </Text>
        </div>
      </div>

      <div className="flex w-full items-center justify-between border-t pt-8">
        <Link to={`/applications/${application.id}`}>
          <Button fullWidth variant="filled">
            Подробнее
          </Button>
        </Link>

        <Link to={`/applications/${application.id}`}>
          <Button
            color="F3F3F7"
            fullWidth
            leftSection={<IconDownload />}
            variant="outline"
          >
            Скачать
          </Button>
        </Link>
      </div>
    </div>
  );
}
