import { t } from 'i18next';

import SafetyIcon from '@components/atoms/icons/SafetyIcon';
import StatementIcon from '@components/atoms/icons/StatementIcon';

export interface SidebarInterface {
  children?: SidebarInterface[];
  icon?: any;
  key: string;
  link: string;
  title?: string;
}

export const sidebars = (): SidebarInterface[] => {
  return [
    {
      icon: SafetyIcon,
      key: 'contracts',
      link: 'contracts',
      title: `${t('contracts.contracts')}`,
    },
    {
      icon: StatementIcon,
      key: 'applications',
      link: 'applications',
      title: `${t('applications.applications')}`,
    },
  ];
};
