export const kzTranslations = {
  translation: {
    applications: {
      applications: 'Мәлімдеме',
      notFound: 'В данном разделе отсутствуют заявления',
    },
    auth: {
      agrrement: 'Мен Пайдаланушы келісімінің шарттарын оқыдым және келісемін',
      auth: 'Кіру',
      bin: 'БИН/ИИН',
      email: 'Email',
      emailOtpMessage: 'На ваш email придет ОТР-код для подтверждения',
      enter: 'Войти',
      enterCode: 'Введите код',
      errors: {
        USER_BLOCKED: 'Введенный номер заблокирован.',
        USER_EXISTS_BY_BIN:
          'Введенный БИН уже зарегистрирован. Войдите в систему.',
        USER_EXISTS_BY_IDENTIFIER:
          'Введенный ИИН уже зарегистрирован. Войдите в систему.',
        USER_EXISTS_BY_PHONE:
          'Введенный номер уже зарегистрирован. Войдите в систему.',
        USER_NOT_FOUND:
          'Введенный номер ещё не зарегистрирован. Зарегестрируйтесь.',
        basic: 'Что-то пошло не так, попробуйте позднее.',
        otpCodeNotValid: 'Қате код',
        userNotFound: 'Енгізілген телефон нөмірі бар пайдаланушы табылмады',
      },
      getCode: 'Получить код',
      getSms: 'Получить код',
      haveAnAccount: 'Сіз әлдеқашан тіркелгенсіз бе?',
      iin: 'ИИН/БИН',
      login: 'Кіру',
      noAccount: 'Сіз тіркелмегенсіз бе?',
      otpCode: 'ОТР-код',
      otpMessage: 'Нөміріңізді растау үшін телефоныңызға OTP коды жіберіледі',
      phoneNumber: 'Телефон нөмірі',
      register: 'Зарегистрироваться',
      registration: 'Тіркеу',
      welcome: 'қош келдіңіз',
    },
    chooseLanguage: 'Тілді таңдаңыз',
    contracts: {
      contracts: 'Шарттар',
      notFound: 'В данном разделе отсутствуют договоры',
    },
    footer: {
      aboutCompany: 'О компании',
      address:
        ' г. Алматы, <br />050051, <br /> Республика Казахстан, <br /> пр. Назарбаева, 22<br /> Пн-Пт: 9.00-18.00, <br />Обед: 13.00-14.00',
      allRightsReserved: 'Все права защищены',
      callCenter: 'Телефон контакт-центра',
      checkInsurancePolicy: 'Проверить страховой полис',
      clients: 'Клиентам',
      contactUs: 'Связаться с нами',
      contacts: 'Контакты',
      cookie: 'Соглашение о файлах Cookie',
      documents: 'Документы и шаблоны',
      employeeInsurance: 'Обязательное страхование работников',
      financialLiteracy: 'Финансовая грамотность',
      forBusiness: 'Бизнесу',
      forClients: 'Частным лицам',
      haveQuestions: 'Остались вопросы?',
      legal: 'Политики',
      license:
        'Лицензия №2.2.12 от 28.12.2022 г. на право осуществления страховой (перестраховочной) деятельности по отрасли «Страхование жизни», выданная Агентством Республики Казахстан по регулированию и развитию финансового рынка',
      licenseAndCertificates: 'Лицензии и Сертификаты',
      lifeInsurance: 'Страхование жизни',
      management: 'Руководство',
      navigation: 'Навигация',
      news: 'Новости',
      personalAccidentInsurance: 'Страхование от несчастных случаев (NS)',
      privacyPolicy: 'Политика конфиденциальности',
      products: 'Продукты',
      services: 'Сервисы',
      siteMap: 'Карта сайта',
      telegramSupport: 'Поддержка в Telegram',
      termsOfService: 'Условия обслуживания',
      vacancies: 'Вакансии',
    },
    goBack: 'Вернуться назад',
    locales: {
      kz: 'Қазақша',
      ru: 'Русский',
    },
    locales_short: {
      kz: 'KZ',
      ru: 'RU',
    },
    navbar: {
      about: 'О компании',
      aboutCompany: 'О компании',
      borrowersLifeInsurance: 'Страхование жизни',
      contactsAndDetails: 'Контакты и реквизиты',
      documents: 'Документы и шаблоны',
      employeeInsurance: 'Страхование работников',
      employeeInsuranceApplication: 'Заявка на страхование работников',
      financialLiteracy: 'Финансовая грамотность',
      insuranceCase: 'Страховой случай',
      licenseAndCertificates: 'Лицензии и Сертификаты',
      lifeInsurance: 'Страхование жизни',
      management: 'Руководство',
      news: 'Новости',
      personalDataProcessing: 'Обработка персональных данных',
      vacancy: 'Вакансии',
    },
    statuses: {
      ACTIVE: 'Белсенді',
      EXPIRED: 'Жарамдылық мерзімі өткен',
      IN_PROCESSING_DOCUMENTS: 'Қарастырылуда',
      IN_PROCESSING_PAYMENT: 'Қарастырылуда',
      PAYMENT_SENT: 'Төлем расталды',
      REJECTED: 'Отклонено',
      STATEMENT_ACCEPTED: 'Заявление принято',
      WAITING_FOR_DOCUMENTS: 'Құжаттарды күтеміз',
      WAITING_FOR_PAYMENT_APPLICATION: 'Ожидаем заявление на выплату',
    },
  },
};
