import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IUser } from '@/types/IUser';

type IUserResponse = AxiosResponse<IUser>;

export function useCurrent() {
  return useQuery<IUserResponse, AxiosError>(
    [queryKeys.user],
    () => api.get(`/user-service/api/users/current`),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess),
      refetchOnMount: false,
      retry: false,
    }
  );
}
