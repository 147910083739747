import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgBccShieldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="42px" viewBox="0 0 24 24" width="42px" {...props}>
    <path
      d="M11.0002 2.46108C11.0002 2.07322 11.0002 1.87929 10.9187 1.75465C10.8475 1.64561 10.7365 1.56869 10.6094 1.54025C10.4641 1.50774 10.2825 1.57583 9.91936 1.71201L5.5949 3.33357C4.97711 3.56422 4.43457 3.76677 4.01675 4.13499C3.65123 4.45712 3.36967 4.86341 3.1964 5.31876C2.99833 5.83927 2.99917 6.41839 3.00013 7.07783L3.00026 11.9997C3.00026 14.8263 4.53755 17.1829 6.19932 18.9141C7.27832 20.0382 8.51574 21.0549 9.83959 21.9048C10.1932 22.1318 10.3701 22.2454 10.536 22.2334C10.6758 22.2234 10.8165 22.1465 10.9005 22.0343C11.0002 21.9011 11.0002 21.6821 11.0002 21.2441V2.46108Z"
      fill="#0B7B94"
    />
    <path
      d="M13 21.2441C13 21.6821 13 21.9011 13.0997 22.0343C13.1837 22.1465 13.3244 22.2234 13.4642 22.2335C13.6301 22.2454 13.807 22.1319 14.1606 21.9048C15.4845 21.0549 16.7219 20.0382 17.801 18.9141C19.4627 17.1829 21 14.8263 21 11.9997L21.0002 7.07783C21.0011 6.41839 21.0019 5.83927 20.8039 5.31877C20.6306 4.86342 20.349 4.45713 19.9835 4.135C19.5657 3.76678 19.0232 3.56423 18.4054 3.33357L14.0809 1.712C13.7177 1.57582 13.5361 1.50773 13.3908 1.54025C13.2637 1.56869 13.1527 1.64561 13.0815 1.75465C13 1.87928 13 2.07321 13 2.46107V21.2441Z"
      fill="#0B7B94"
    />
  </svg>
);
const Memo = memo(SvgBccShieldIcon);
export default Memo;
