import { useQueryClient } from 'react-query';

import { AxiosError, AxiosResponse } from 'axios';
import { Maybe, object, ObjectSchema, string } from 'yup';

import { queryKeys } from '@consts/queryKeys';
import { useMutation } from '@tanstack/react-query';
import { api } from '@utils/index';
import { notify } from '@utils/notify';

import { IBackendError } from '@/types/common/IBackendError';
import { IAuth } from '@/types/IAuth';

export type IRequisitesRequest = {
  bankAccount: string;
  bankName: string;
  recipientsAccount: string;
};

export const requisitesInitial: IRequisitesRequest = {
  bankAccount: '',
  bankName: '',
  recipientsAccount: '',
};

export const requisitesValidation: ObjectSchema<IRequisitesRequest> =
  object().shape({
    bankAccount: string().required(),
    bankName: string().required(),
    recipientsAccount: string().required(),
  });

export function useRequisites({ ...params }: { statementId: number }) {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<IAuth>,
    AxiosError<Maybe<IBackendError>>,
    IRequisitesRequest
  >(
    (payload) =>
      api.post(`/contract-service/api/requisite`, payload, {
        params: {
          statementId: params.statementId,
        },
      }),
    {
      onError: (error) => {
        notify('error', error.message);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.statements]),
          queryClient.invalidateQueries([queryKeys.statement]);
        notify('success', ' Заявление на выплату отправлено!');
      },
    }
  );
}
