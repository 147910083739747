import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import SafetyIcon from '@components/atoms/icons/SafetyIcon';
import {
  ActionIcon,
  Button,
  CopyButton,
  rem,
  Text,
  Tooltip,
} from '@mantine/core';
import { ApplicationStatusTag } from '@modules/applications/components/atoms/ApplicationStatusTag';
import { IconCheck, IconCopy, IconDownload } from '@tabler/icons-react';

import { IContract } from '@/types/IContract';

export default function ContractCard({ contract }: { contract: IContract }) {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return (
    <div className="delay-80 flex max-w-[590px] flex-col justify-between rounded-[32px] bg-white p-8 shadow-sm transition ease-in-out hover:shadow-md">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-6">
          <SafetyIcon stroke="1.2" style={{ height: '55px', width: '55px' }} />
          <Tooltip
            label={
              language == 'kz'
                ? contract.contractNameKz
                : contract.contractNameRu
            }
          >
            <Text className="truncate" color="#495057" fw={800} size="lg">
              {(language == 'kz'
                ? contract.contractNameKz
                : contract.contractNameRu) ?? '-'}{' '}
            </Text>
          </Tooltip>
        </div>

        <div className="border-1 ml-3 flex items-center gap-3 rounded-md border border-[#F1F3F7] bg-[#F8FAFF] p-[10px]">
          <Text color="#6D758F" fw={600} size="sm">
            № {contract.contractNumber}
          </Text>
          <CopyButton timeout={2000} value={contract.contractNumber}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? 'Скопировано!' : 'Скопировать'}
                position="right"
                withArrow
              >
                <ActionIcon
                  color={copied ? 'teal' : 'gray'}
                  onClick={copy}
                  size={14}
                  variant="subtle"
                >
                  {copied ? (
                    <IconCheck style={{ width: rem(16) }} />
                  ) : (
                    <IconCopy style={{ width: rem(16) }} />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-5 py-8 sm:grid-cols-2">
        <div>
          <Text color="#6D758F" fw={400} size="sm">
            Застрахованный:
          </Text>
          <Text color="#464C5D" fw={500} size="md">
            Сулейманова Анара Ф.
          </Text>
        </div>

        <div>
          <Text color="#6D758F" fw={400} size="sm">
            Cтатус:
          </Text>
          <ApplicationStatusTag status={contract.status} />
        </div>

        <div>
          <Text color="#6D758F" fw={400} size="sm">
            Страховая сумма:
          </Text>
          <Text color="#464C5D" fw={500} size="md">
            {contract.sumInsured || '-'}
          </Text>
        </div>

        <div>
          <Text color="#6D758F" fw={400} size="sm">
            Страховая премия:
          </Text>
          <Text color="#464C5D" fw={500} size="md">
            {contract.premiumInsurance || '-'}
          </Text>
        </div>
      </div>

      <div className="flex w-full items-center justify-between border-t pt-8">
        <Link to={`/contracts/${contract.id}`}>
          <Button fullWidth variant="filled">
            Подробнее
          </Button>
        </Link>

        <Link to={`/contracts/${contract.id}`}>
          <Button fullWidth leftSection={<IconDownload />} variant="outline">
            Скачать
          </Button>
        </Link>
      </div>
    </div>
  );
}
