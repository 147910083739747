import { useTranslation } from 'react-i18next';

import GlobeIcon from '@components/atoms/icons/GlobeIcon';
import { Button, Menu } from '@mantine/core';
import { IconCaretDownFilled } from '@tabler/icons-react';

import {
  IEnumLocalization,
  IEnumLocalizationKz,
} from '@/types/common/ILocalization';

export default function LanguageDropdown() {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return (
    <Menu classNames={{ dropdown: 'rounded-lg mt-1' }} keepMounted>
      <Menu.Target>
        <Button
          className="border-none !bg-transparent !font-normal capitalize"
          color="#001D6C"
          leftSection={
            <GlobeIcon stroke="1.2" style={{ height: '16px', width: '16px' }} />
          }
          rightSection={
            <IconCaretDownFilled className="text-[#001D6C]" size={16} />
          }
          variant="white"
        >
          {language == 'ru'
            ? IEnumLocalization[language]
            : IEnumLocalizationKz[language]}
        </Button>
      </Menu.Target>

      <Menu.Dropdown className="!z-[11000]">
        {Object.keys(
          language == 'ru' ? IEnumLocalization : IEnumLocalizationKz
        ).map((locale) => (
          <Menu.Item
            className="hover:text-primary font-medium hover:font-semibold"
            key={`localization-${locale}`}
            onClick={() => {
              localStorage.setItem('language', locale);
              window.location.reload();
            }}
          >
            {locale == 'ru'
              ? IEnumLocalization[locale]
              : IEnumLocalizationKz[locale]}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}
