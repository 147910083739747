import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import {
  IStatemenntDocumentRequirement,
} from '@/types/IApplication';

type IStatementsResponse = IPageAbleResponse<IStatemenntDocumentRequirement>;

export function useStatementRequirements({
  ...params
}: IPageAbleRequest & {
  product: 'LIFE' | 'OSNS';
  reason?:
    | 'LIFE_DEATH_DUE_TO_ILLNESS'
    | 'LIFE_DEATH_DUE_TO_ACCIDENT'
    | 'LIFE_DISABILITY_DUE_TO_ACCIDENT'
    | 'OSNS_LOSS_OF_PROFESSIONAL_ABILITY'
    | 'OSNS_EMPLOYEE_DEATH';
}) {
  return useQuery<IStatementsResponse, AxiosError>(
    [queryKeys.statements, params],
    () =>
      api
        .get(`/contract-service/api/requirements/get-all-requirements`, {
          params,
        })
        .then((res) => res.data),
    {
      enabled:
        !!localStorage.getItem(storageKeys.tokenAccess) && !!params.reason,
    }
  );
}
