import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageMeta } from '@context/PageMetaContext';
import { Button } from '@mantine/core';
import { IconArrowBack } from '@tabler/icons-react';

const AgreementPage: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <PageMeta title="Условия Пользовательского соглашения" />

      <div className="p-[80px]">
        <Button
          className="w-[200px] border-[#EAECEE] font-thin text-[#0C1116]"
          leftSection={<IconArrowBack className="h-4 w-4 text-gray-400" />}
          onClick={() => navigate('/register')}
          variant="outline"
        >
          Вернуться назад
        </Button>
        <div className="pt-[80px]">Условия Пользовательского соглашения</div>
      </div>
    </>
  );
};

export default AgreementPage;
