import { createContext, FC, ReactNode, useContext } from 'react';

import { useCurrent } from '@api/auth/useCurrent';
import { storageKeys } from '@consts/storageKeys';

import { IUser } from '@/types/IUser';

type UserContextValues = {
  userMe?: IUser;
  userMeError: boolean;
  userMeFetching: boolean;
  userMeLoading: boolean;
};

export const UserContext = createContext<UserContextValues>({
  userMe: undefined,
  userMeError: false,
  userMeFetching: !!localStorage.getItem(storageKeys.tokenAccess),
  userMeLoading: !!localStorage.getItem(storageKeys.tokenAccess),
});

export const UserContextProvider: FC<{ children?: ReactNode }> = (props) => {
  const userMeQuery = useCurrent();
  const userMe = userMeQuery.data?.data;

  return (
    <UserContext.Provider
      value={{
        userMe: userMe,
        userMeError: userMeQuery.isError,
        userMeFetching: userMeQuery.isFetching,
        userMeLoading: userMeQuery.isLoading,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const userContext = useContext(UserContext);

  if (typeof userContext === 'undefined') {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }

  return userContext;
};
