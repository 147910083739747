import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { t } from 'i18next';

import { useStatementById } from '@api/applications/useStatementById';
import StatementIcon from '@components/atoms/icons/StatementIcon';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { PageMeta } from '@context/PageMetaContext';
import { Skeleton, Stepper } from '@mantine/core';

import ApplicationComplete from '../components/organisms/ApplicationComplete';
import ApplicationDetails from '../components/organisms/ApplicationDetails';
import ApplicationPayment from '../components/organisms/ApplicationPayment';

const ApplicationDetailsPage: FC = () => {
  const { id = '' } = useParams<{ id: string }>();

  const statementQuery = useStatementById({
    id: +id,
  });

  const statement = statementQuery.data?.data;

  // FIXME: depends on status
  const active = useMemo(() => {
    if (statement) {
      switch (statement.status) {
        case 'WAITING_FOR_DOCUMENTS':
        case 'IN_PROCESSING_DOCUMENTS':
        case 'REJECTED':
          return 1;
        case 'WAITING_FOR_PAYMENT_APPLICATION':
        case 'IN_PROCESSING_PAYMENT':
          return 2;
        case 'STATEMENT_ACCEPTED':
          return 3;
        default:
          return 1;
      }
    } else {
      return 1;
    }
  }, [statement]);

  return (
    <div className="application-width mx-auto flex">
      <PageMeta
        breadcrumbs={[
          { link: '/applications', title: t('applications.applications') },
        ]}
        openMenuKeys={['applications']}
        selectedMenuKeys={['applications']}
        title={t('applications.applications')}
      />
      {statementQuery.isLoading ? (
        <Skeleton />
      ) : !statement ? (
        <NotFoundResult />
      ) : (
        <div className="my-8 grid grid-cols-9 gap-5">
          <div className="col-span-6 grid gap-6">
            {active == 1 && <ApplicationDetails statement={statement} />}
            {active == 2 && (
              <ApplicationPayment
                onFinish={() => console.log(3)}
                statement={statement}
              />
            )}
            {active == 3 && <ApplicationComplete />}
          </div>

          <div className="col-span-3">
            <Stepper
              active={active}
              allowNextStepsSelect={false}
              icon={<StatementIcon />}
              orientation="vertical"
            >
              <Stepper.Step
                description="Извещение о страховом случае"
                icon={<StatementIcon />}
                label="1 Шаг"
              />
              <Stepper.Step description="Загрузка документов" label="2 Шаг" />
              <Stepper.Step
                description="Подать заявление на страховую выплату"
                label="3 Шаг"
              />
              <Stepper.Step description="Заявление принято" label="4 Шаг" />
            </Stepper>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicationDetailsPage;
