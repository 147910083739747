import { AxiosError } from 'axios';
import { Maybe, object, ObjectSchema, string } from 'yup';

import { useMutation } from '@tanstack/react-query';
import { api } from '@utils/index';
import { notify } from '@utils/notify';

import { IBackendError } from '@/types/common/IBackendError';

export type IUserOtpSendRequest = {
  identifier: string;
};

export const otpSendInitial: IUserOtpSendRequest = {
  identifier: '',
};

export const otpSendValidation: ObjectSchema<IUserOtpSendRequest> =
  object().shape({
    identifier: string()
      .required('Поле обязательно для заполнения')
      .min(13, 'ИИН/БИН должен быть не менее 12 символов')
      .max(13, 'ИИН/БИН должен быть не более 12 символов'),
  });

export function useOtpSend() {
  return useMutation<
    void,
    AxiosError<Maybe<IBackendError>>,
    IUserOtpSendRequest
  >((payload) => api.post('/user-service/api/auth/resend-otp', payload), {
    onError: (error) => {
      notify('error', error.message);
    },
  });
}
