import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import AuthFooter from '@components/organisms/AuthFooter';
import AuthRoute from '@routes/AuthRoute';

const AuthLayout: FC = () => {
  return (
    <AuthRoute auth={false}>
      <div className="grid h-auto w-full ">
        {/* <div className="bg-[#F3F3F7]">
          <Navbar />
        </div> */}
        <div className="application-width col-span-2 mx-auto flex flex-grow flex-col items-center justify-center bg-[#F3F3F7] py-[120px] lg:col-span-1 sm:m-auto">
          <Outlet />
        </div>
        <div className="bg-[#F3F3F7]">
          <AuthFooter />
        </div>
      </div>
    </AuthRoute>
  );
};

export default AuthLayout;
