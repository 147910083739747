import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgStatementIconsvg = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="42px" viewBox="0 0 25 24" width="42px" {...props}>
    <mask
      height={20}
      id="mask0_3236_7464"
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
      width={21}
      x={2}
      y={2}
    >
      <path
        clipRule="evenodd"
        d="M22.9609 6.5H2.96094V17.5C2.96094 19.7091 4.7518 21.5 6.96094 21.5H16.4609V15H22.9609V6.5ZM17.4038 10.6803L11.7035 16.3806L7.46094 12.1379L7.8144 11.7845C8.5954 11.0034 9.8618 11.0033 10.6429 11.7844L11.7035 12.8451L14.2127 10.3358C14.9803 9.56827 16.2199 9.55307 17.0061 10.3015L17.4038 10.6803Z"
        fill="url(#paint0_linear_3236_7464)"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M18.9609 2.5H2.96094V6.5H22.9609C22.9609 4.29086 21.17 2.5 18.9609 2.5Z"
        fill="url(#paint1_linear_3236_7464)"
        fillRule="evenodd"
      />
      <path d="M22.9609 15H16.4609V21.5L22.9609 15Z" fill="black" opacity={0.4} />
    </mask>
    <g mask="url(#mask0_3236_7464)">
      <path d="M0.96875 0H24.9688V24H0.96875V0Z" fill="#0B7B94" />
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_3236_7464"
        x1={21.4609}
        x2={4.87929}
        y1={19.2778}
        y2={1.21263}
      >
        <stop />
        <stop offset={1} stopOpacity={0.7} />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_3236_7464"
        x1={22.9609}
        x2={3.46094}
        y1={2.5}
        y2={6}
      >
        <stop stopOpacity={0.45} />
        <stop offset={1} stopOpacity={0.3} />
      </linearGradient>
    </defs>
  </svg>
);
const Memo = memo(SvgStatementIconsvg);
export default Memo;
